import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../state/trace.reducer';
import * as TraceActions from '../state/trace.actions';
import { District } from 'src/app/shared/models/district.models';
import { Constants } from 'src/app/shared/models/constant-models';
import { Observable } from 'rxjs';
import { Buck } from 'src/app/shared/models/buck.models';
import { getCurrentBuck, getError } from '../state/trace.selectors';

@Component({
  selector: 'app-trace-container',
  templateUrl: './trace-container.component.html',
  styleUrls: ['./trace-container.component.scss']
})
export class TraceContainerComponent implements OnInit {

  serialNumber: string = '';
  districtNumberFromRoute: number;
  showBuck: boolean = false;
  selectedDistrict: District;
  buck$: Observable<Buck>;
  errorMessage$: Observable<string>;

  constructor(private route: ActivatedRoute, private router: Router, private store: Store<State>, private location: Location) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.serialNumber = params['sn'];
      this.serialNumber = this.serialNumber.toLocaleUpperCase();
      this.districtNumberFromRoute = this.serialNumber.charCodeAt(0) - 65 + 1;
      this.showBuck = this.route.snapshot.routeConfig.path.includes('buck-info');
      this.selectedDistrict = Constants.banks.find(bank => bank.district === this.districtNumberFromRoute);

      this.buck$ = this.store.select(getCurrentBuck);
      this.errorMessage$ = this.store.select(getError);
      this.store.dispatch(TraceActions.setShowBuck({ showBuck: this.showBuck }));
      this.store.dispatch(TraceActions.setSerialNumber({ serialNumber: this.serialNumber }));
      this.store.dispatch(TraceActions.setCurrentDistrict({ currentDistrictId: this.districtNumberFromRoute }));
      this.store.dispatch(TraceActions.setDistrictNumberFromRoute({ districtNumberFromRoute: this.districtNumberFromRoute }));
      this.store.dispatch(TraceActions.loadBuck({ serialNumber: this.serialNumber }));
    });
  }

  navigateTo(whereTo: string) {
    this.store.dispatch(TraceActions.toggleShowSerialNumber());
    switch (whereTo) {
      case 'home':
        this.router.navigateByUrl('/');
        break;
      case 'buck':
        this.showBuck = true;
        this.location.replaceState(`trace/${this.serialNumber}/buck-info`);
        break;
      case 'map':
        this.showBuck = false;
        this.location.replaceState(`trace/${this.serialNumber}/fed-map`);
        break;
      default:
        break;
    }
  }

}

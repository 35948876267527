import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';

//move out into own service file
@Injectable({ providedIn: 'root' })
export class ValidSerialRouteGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean> {
        if (this.isValidSerial(route.params.sn)) {
            return new Observable<boolean>(subscriber => {
                subscriber.next(true);
                subscriber.complete();
            });
        } else {
            // redirect to some view explaining what happened
            this.router.navigateByUrl('/invalidserial');
            return new Observable<boolean>(subscriber => {
                subscriber.next(false);
                subscriber.complete();
            });
        }
    }

    isValidSerial(serialNumber: string): boolean {
        console.log("serialNumber", serialNumber);

        var pattern = new RegExp(/^[a-lA-L]{1}\d{8}[a-np-yA-NP-Y*]{1}$/);
        var isMatch = pattern.test(serialNumber);
        return isMatch;
    }
}

<div class="trace-container">
    <div class="top-panel">
        <div class="logo-panel clickable" (click)="navigateTo('home')">
            <img src="../../../assets/img/icons/bucktrace_logo_white.svg">
            <span>HOME</span>
        </div>
        <div class="serial-number-panel">
            <div class="serial-number-container">
                <div class="serial-number">
                    {{serialNumber}}
                </div>
            </div>
            <div class="nav-panel">
                <div [ngClass]="{'active':!showBuck}">
                    <img src="../../../assets/img/icons/icon_globe_white.svg" class="nav-icon clickable" (click)="navigateTo('map')">
                    <span>MAP</span>
                </div>
                <div [ngClass]="{'active':showBuck}">
                    <img src="../../../assets/img/icons/icon_money_white.svg" class="nav-icon clickable active buck" (click)="navigateTo('buck')">
                    <span>ABOUT</span>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-panel">
        <app-buck-info *ngIf="showBuck" [serialNumber]="serialNumber" style="width:100%;"></app-buck-info>
        <app-fed-map *ngIf="!showBuck" [serialNumber]="serialNumber" style="width:100%;"></app-fed-map>
    </div>
</div>
<div class="mobile-detect">
    <h1>Mobile/Tablet/Small Screen size detected</h1>
    <br>
    <h2>Coming soon for itty bitty screens</h2>
    <br>
    <h3>Meanwhile, Please look for the BuckTrace App</h3>
</div>
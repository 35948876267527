import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as d3 from "d3";
import { Branch, DistrictPojo } from 'src/app/shared/models/district.models';
import { State } from '../state/trace.reducer';
import { Store } from '@ngrx/store';
import * as TraceActions from '../state/trace.actions';
import { Constants } from 'src/app/shared/models/constant-models';
import { getTraceCount } from '../state/trace.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fed-map',
  templateUrl: './fed-map.component.html',
  styleUrls: ['../trace.scss', './fed-map.component.scss']
})
export class FedMapComponent implements OnInit {
  @Input() serialNumber: string = '';
  svg: any = null;
  totalBucksPrinted: number = 2430000000;
  totalBucksCount: number = 0;
  traceCount$: Observable<number>;

  NEUTRAL_COLOR: string = '#9d9d9d';
  GREEN_COLOR: string = '#75a560';
  RED_COLOR: string = 'rgb(110, 53, 53)';
  width = 5044.02; //5044.02 3571.37
  height = 3571.37; //5044.02 3571.37
  zoom: any;
  states: any;
  branches: any;
  BLANK_DISTRICT: DistrictPojo = {
    letter: '',
    mainOfficeSvgId: '',
    name: '',
    svgSectionId: '',
    districtId: 0,
    color: '',
    dScale: 0,
    dx: 0,
    dy: 0
  };
  selectedDistrict: DistrictPojo = this.BLANK_DISTRICT;
  BLANK_BRANCH: Branch = {
    svgId: '',
    name: '',
    parentDistrictId: 0
  };
  selectedBranch: Branch = this.BLANK_BRANCH;
  districtFromRoute: number = 0;
  isZooming: boolean = false;
  parseFloat = parseFloat;
  districtList: DistrictPojo[] = Constants.districtList;
  traceCount: number = 0;

  constructor(private route: ActivatedRoute, private router: Router, private store: Store<State>) { }

  ngOnInit(): void {
    // const svg1 = d3.select("svg").call(
    //   d3.zoom().on("zoom", function (event) {
    //     svg1.select("g#full-map").attr("transform", `translate(${event.transform.x}, ${event.transform.y}) scale(${event.transform.k})`);
    //   })
    // );


    this.traceCount$ = this.store.select(getTraceCount);
    this.traceCount$.subscribe(count => {
      this.traceCount = count;
    })
    this.totalBucksCount = this.districtList.reduce((partialSum, a) => partialSum + a.numberOfBucks, 0);
    this.initMap();
  }


  initMap() {
    this.svg = document.getElementById("svg");

    this.districtList.forEach(district => {
      d3.select(`#${district.svgSectionId}`).transition().style('fill', `${this.NEUTRAL_COLOR}`).style('fill-opacity', '.7');
    });
    this.states = d3.selectAll('.district');
    this.states.on('click', event => this.districtClicked(event, this));
    this.states.on('mouseover', event => this.onMouseOver(event, this));
    this.states.on('mouseout', event => this.onMouseOut(event, this));

    this.branches = d3.selectAll('.branches');
    this.branches.on('click', event => this.branchClicked(event, this));

    //65-90
    const districtToOpen = this.serialNumber.charCodeAt(0) - 65;
    this.districtFromRoute = districtToOpen + 1;
    this.changeDistrict(this.districtList[districtToOpen], true);
  }

  onMouseOver(e: any, parent: any) {
    const selectedDistrictId = e.target.parentElement.id;
    if (this.isZooming) {
      return;
    }
    if (selectedDistrictId !== '') {
      if (this.selectedDistrict.svgSectionId !== '') {
        if (this.selectedDistrict.svgSectionId !== selectedDistrictId) {
          d3.select(`#${selectedDistrictId}`).transition().style('fill', `${this.NEUTRAL_COLOR}`).style('fill-opacity', '.7');
        }
      } else {
        d3.select(`#${selectedDistrictId}`).transition().style("fill", `${this.NEUTRAL_COLOR}`).style("fill-opacity", "1");
      }
    }
  }

  onMouseOut(e: any, parent: any) {
    const selectedDistrictId = e.target.parentElement.id;
    if (this.isZooming) {
      return;
    }
    if (selectedDistrictId !== '') {
      if (this.selectedDistrict.svgSectionId !== '') {
        if (this.selectedDistrict.svgSectionId !== selectedDistrictId) {
          d3.select(`#${selectedDistrictId}`).transition().style("fill", `${this.NEUTRAL_COLOR}`).style("fill-opacity", "0.1");
        }
      } else {
        d3.select(`#${selectedDistrictId}`).transition().style('fill', `${this.NEUTRAL_COLOR}`).style('fill-opacity', '.7');
      }
    }
  }

  districtClicked(e, parent) {
    const selectedDistrictId = e.target.parentElement.id === '' ? e.target.parentElement.parentElement.id : e.target.parentElement.id;
    const district = parent.districtList.find(district => district.svgSectionId === selectedDistrictId);
    parent.changeDistrict(district, true);
  }

  branchClicked(e, parent) {
    const selectedDistrictId = e.target.parentElement.id;
    const selectedBranchId = e.target.id;
    console.log('branch clicked', e);
    const district: DistrictPojo = parent.districtList.find(district => district.svgSectionId === selectedDistrictId);
    const branch = district.branches.find(branch => branch.svgId === selectedBranchId);
    e.stopPropagation();
    parent.selectBranch(district, branch);
  }

  changeDistrict(district: DistrictPojo, selectCity: boolean) {
    this.selectedDistrict = district;
    this.isZooming = true;
    this.removePulse();
    const svg = d3.select("g#full-map");
    svg.transition().duration(750).attr("transform", `translate(${district.dx}, ${district.dy}) scale(${district.dScale})`);
    setTimeout(() => {
      d3.selectAll('.district').transition().style("fill", `${this.NEUTRAL_COLOR}`).style("fill-opacity", "0.1");
      if (this.districtFromRoute === district.districtId) {
        d3.select(`#${district.svgSectionId}`).transition().style("fill", `${this.RED_COLOR}`).style("fill-opacity", "1");
      } else {
        d3.select(`#${district.svgSectionId}`).transition().style("fill", `${this.GREEN_COLOR}`).style("fill-opacity", "1");
      }
      if (selectCity) {
        district.isCitySelected = true;
        svg.select(`#${district.mainOfficeSvgId}`)
          .attr('style', `animation: pulse 1.5s ease-in-out infinite`)
          .attr('class', `pulse`);
      }
      this.isZooming = false;
    }, 400);
  }

  selectBranch(district: DistrictPojo, branch: Branch) {
    if (this.selectedDistrict !== district) {
      this.changeDistrict(district, false);
    }
    this.removePulse();
    this.selectedBranch = branch;
    this.svg.getElementById(branch.svgId).setAttribute('style', `animation: pulse1 1.5s ease-in-out infinite`);
    this.svg.getElementById(branch.svgId).setAttribute('class', `pulse1`);
  }

  removePulse() {
    this.districtList.forEach(district => {
      const d = this.svg.getElementById(district.mainOfficeSvgId);
      d.setAttribute('style', ``);
      d.setAttribute('style', `fill: #2C5E1A`);
      d.setAttribute('class', `main-office`);
      district.isCitySelected = false;
      this.selectedBranch = {
        svgId: '',
        name: '',
        parentDistrictId: 0
      };
      if (district.branches) {
        district.branches.forEach(branch => {
          this.svg.getElementById(branch.svgId).setAttribute('class', `branches`);
          this.svg.getElementById(branch.svgId).setAttribute('style', ``);
        })
      }
    });
  }

  resetMap() {
    this.selectedDistrict = this.BLANK_DISTRICT;
    this.selectedBranch = this.BLANK_BRANCH;
    this.removePulse();
    const svg = d3.select("g#full-map");
    d3.selectAll('.district').style('fill', `${this.NEUTRAL_COLOR}`).style("fill-opacity", ".7");
    svg.transition().duration(750).attr("transform", `translate(0, 0) scale(1)`);
  }

  navigateTo(whereTo: string) {
    switch (whereTo) {
      case 'home':
        this.router.navigateByUrl('/');
        break;
      case 'buck':
        this.router.navigateByUrl(`/trace/${this.serialNumber}/about-buck`);
        break;
      case 'map':
        break;
      default:
        break;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/shared/models/constant-models';
import * as d3 from "d3";
import { Store } from '@ngrx/store';
import { BuckElementLocation, BuckElementType } from 'src/app/shared/models/buck.models';
import { State } from '../state/trace.reducer';
import { getCurrentSerialNumber, getShowSerialNumber, getDistrictNumberFromRoute } from '../state/trace.selectors';
import * as TraceActions from '../state/trace.actions';

@Component({
  selector: 'app-buck-info',
  templateUrl: './buck-info.component.html',
  styleUrls: ['../trace.scss', './buck-info.component.scss']
})

export class BuckInfoComponent implements OnInit {
  @Input() serialNumber: string = '';
  info: string = '';
  hasErrors: boolean = false;
  errors: string = '';
  series: any[] = Constants.series;
  selectedSeriesItem: any = {
    TreasurerFullName: '',
    Series: '2013'
  };
  buck: any = { SN: 'asdf' };
  photoRoot: string = '../../assets/img/';
  fedNumber: string = 'a';
  serialDigits: string = "xxxxxxxxxx";
  selectedDistrict: any = {};
  buckElements: BuckElementLocation[] = [
    {
      type: BuckElementType.SEAL,
      x: 310,
      y: 250,
      scale: 1.8,
      id: '',
      zoomX: 700,
      zoomY: -50,
      zoomScale: 2.5
    },
    {
      type: BuckElementType.FED_NUMBER_TOP_LEFT,
      x: 240,
      y: 289,
      scale: .35,
      id: '',
      zoomX: -350,
      zoomY: -200,
      zoomScale: 1.2
    },
    {
      type: BuckElementType.FED_NUMBER_BOTTOM_LEFT,
      x: 225,
      y: 510,
      scale: .35,
      id: '',
      zoomX: -350,
      zoomY: -200,
      zoomScale: 1.2
    },
    {
      type: BuckElementType.FED_NUMBER_TOP_RIGHT,
      x: 1340,
      y: 280,
      scale: .35,
      id: '',
      zoomX: -350,
      zoomY: -200,
      zoomScale: 1.2
    },
    {
      type: BuckElementType.FED_NUMBER_BOTTOM_RIGHT,
      x: 1353,
      y: 499,
      scale: .35,
      id: '',
      zoomX: -350,
      zoomY: -200,
      zoomScale: 1.2
    },
    {
      type: BuckElementType.FW,
      x: 1357,
      y: 435,
      scale: .25,
      id: '',
      zoomX: -1550,
      zoomY: -300,
      zoomScale: 2.5
    },
    {
      type: BuckElementType.PLATE,
      x: 1380,
      y: 410,
      scale: .70,
      id: '',
      zoomX: -1500,
      zoomY: -300,
      zoomScale: 2.5
    },
    {
      type: BuckElementType.GRID,
      x: 180,
      y: 285,
      scale: .35,
      id: '',
      zoomX: 1000,
      zoomY: 50,
      zoomScale: 2.5
    },
    {
      type: BuckElementType.TREASURER_LABEL,
      x: 297,
      y: 485,
      scale: 1.65,
      id: '',
      zoomX: 300,
      zoomY: 300,
      zoomScale: 3
    },
    {
      type: BuckElementType.SECRETARY_LABEL,
      x: 1147,
      y: 495,
      scale: 1.5,
      id: '',
      zoomX: 300,
      zoomY: 300,
      zoomScale: 3
    },
    {
      type: BuckElementType.SERIAL_BOTTOM_LEFT,
      x: 200,
      y: 440,
      scale: .4,
      id: '',
      zoomX: -375,
      zoomY: 0,
      zoomScale: 1.15
    },
    {
      type: BuckElementType.SERIAL_TOP_RIGHT,
      x: 1020,
      y: 180,
      scale: .4,
      id: '',
      zoomX: -375,
      zoomY: 0,
      zoomScale: 1.15
    },
    {
      type: BuckElementType.TREASURER,
      x: 270,
      y: 405,
      scale: 2.45,
      id: '',
      zoomX: 600,
      zoomY: -600,
      zoomScale: 2.5
    },
    {
      type: BuckElementType.SECRETARY,
      x: 1125,
      y: 430,
      scale: 2.1,
      id: '',
      zoomX: -1100,
      zoomY: -600,
      zoomScale: 2.5
    },
    {
      type: BuckElementType.SERIES,
      x: 1040,
      y: 495,
      scale: .55,
      id: '',
      zoomX: -1100,
      zoomY: -600,
      zoomScale: 2.5
    }
  ]
  showSerial: boolean = true;

  districtNumberFromRoute: number;
  svgBuckElements: any;
  selectedElementType: any;
  selectedBuckElement: any;
  BuckElementType = BuckElementType;
  NEUTRAL_FILTER: string = 'invert(0%) sepia(3%) saturate(5%) hue-rotate(216deg) brightness(95%) contrast(100%)';
  HIGHLIGHT_FILTER: string = 'invert(100%) sepia(3%) saturate(7487%) hue-rotate(240deg) brightness(104%) contrast(102%)';
  GREEN_FILTER: string = 'invert(12%) sepia(86%) saturate(1891%) hue-rotate(128deg) brightness(90%) contrast(91%)';

  constructor(private route: ActivatedRoute, private router: Router, private store: Store<State>) { }


  ngOnInit(): void {
    this.setupSubscriptions();
    this.selectedSeriesItem = Constants.series.find(item => item.Series === '2013');
    //this.highlightElement(BuckElementType[BuckElementType.SERIAL_BOTTOM_LEFT]);
  }

  setupSubscriptions() {
    this.store.select(getShowSerialNumber).subscribe({
      next: (showSerialNumber) => { this.showSerial = showSerialNumber; }
    });
    this.store.select(getCurrentSerialNumber).subscribe({
      next: (currentSerialNumber) => { this.serialNumber = currentSerialNumber; }
    });
    this.store.select(getDistrictNumberFromRoute).subscribe({
      next: (districtNumberFromRoute) => {
        this.districtNumberFromRoute = districtNumberFromRoute;
        this.selectedDistrict = Constants.banks.find(bank => bank.district === districtNumberFromRoute);
        this.initSvgs();
      }
    });
  }

  initSvgs() {
    const svg1 = d3.select("#dollar-svg");

    this.appendBuckElement(svg1, BuckElementType.SEAL, `../../../assets/img/seals/seal-${this.serialNumber.substring(0, 1).toLocaleLowerCase()}-white.svg`);
    this.appendBuckElement(svg1, BuckElementType.FED_NUMBER_TOP_LEFT, `../../../assets/img/district-numbers/fed-${this.districtNumberFromRoute}-white.svg`);
    this.appendBuckElement(svg1, BuckElementType.FED_NUMBER_BOTTOM_LEFT, `../../../assets/img/district-numbers/fed-${this.districtNumberFromRoute}-white.svg`);
    this.appendBuckElement(svg1, BuckElementType.FED_NUMBER_BOTTOM_RIGHT, `../../../assets/img/district-numbers/fed-${this.districtNumberFromRoute}-white.svg`);
    this.appendBuckElement(svg1, BuckElementType.FED_NUMBER_TOP_RIGHT, `../../../assets/img/district-numbers/fed-${this.districtNumberFromRoute}-white.svg`);
    this.appendBuckElement(svg1, BuckElementType.FW, "../../../assets/img/misc/fw-white.svg");
    this.appendBuckElement(svg1, BuckElementType.TREASURER, "../../../assets/img/sigs/rios-white.svg");
    this.appendBuckElement(svg1, BuckElementType.SECRETARY, "../../../assets/img/sigs/lew-white.svg");
    this.appendBuckElement(svg1, BuckElementType.SERIES, "../../../assets/img/series-years/series-2013-white.svg");
    this.appendBuckElement(svg1, BuckElementType.PLATE, "../../../assets/img/misc/plate-white.svg");
    this.appendBuckElement(svg1, BuckElementType.GRID, "../../../assets/img/misc/grid-white.svg");

    this.appendBuckElement(svg1, BuckElementType.SEAL, `../../../assets/img/seals/seal-${this.serialNumber.substring(0, 1).toLocaleLowerCase()}.svg`);
    this.appendBuckElement(svg1, BuckElementType.FED_NUMBER_TOP_LEFT, `../../../assets/img/district-numbers/fed-${this.districtNumberFromRoute}.svg`);
    this.appendBuckElement(svg1, BuckElementType.FED_NUMBER_BOTTOM_LEFT, `../../../assets/img/district-numbers/fed-${this.districtNumberFromRoute}.svg`);
    this.appendBuckElement(svg1, BuckElementType.FED_NUMBER_BOTTOM_RIGHT, `../../../assets/img/district-numbers/fed-${this.districtNumberFromRoute}.svg`);
    this.appendBuckElement(svg1, BuckElementType.FED_NUMBER_TOP_RIGHT, `../../../assets/img/district-numbers/fed-${this.districtNumberFromRoute}.svg`);
    this.appendBuckElement(svg1, BuckElementType.FW, "../../../assets/img/misc/fw.svg");
    this.appendBuckElement(svg1, BuckElementType.TREASURER_LABEL, "../../../assets/img/sigs/treasurer_label.svg");
    this.appendBuckElement(svg1, BuckElementType.TREASURER, "../../../assets/img/sigs/rios.svg");
    this.appendBuckElement(svg1, BuckElementType.SECRETARY_LABEL, "../../../assets/img/sigs/secretary_label.svg");
    this.appendBuckElement(svg1, BuckElementType.SECRETARY, "../../../assets/img/sigs/lew.svg");
    this.appendBuckElement(svg1, BuckElementType.SERIES, "../../../assets/img/series-years/series-2013.svg");
    this.appendBuckElement(svg1, BuckElementType.PLATE, "../../../assets/img/misc/plate.svg");
    this.appendBuckElement(svg1, BuckElementType.GRID, "../../../assets/img/misc/grid.svg");


    let element = this.buckElements.find(element => element.type === BuckElementType.SERIAL_BOTTOM_LEFT);
    let serialLocationX = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const offset = 31;
    serialLocationX[0] = element.x;
    serialLocationX[1] = serialLocationX[0] + 37;
    serialLocationX[2] = serialLocationX[1] + offset;
    serialLocationX[3] = serialLocationX[2] + offset;
    serialLocationX[4] = serialLocationX[3] + offset;
    serialLocationX[5] = serialLocationX[4] + offset;
    serialLocationX[6] = serialLocationX[5] + offset;
    serialLocationX[7] = serialLocationX[6] + offset;
    serialLocationX[8] = serialLocationX[7] + offset;
    serialLocationX[9] = serialLocationX[8] + 40;

    for (let i = 0; i < 10; i++) {
      let svgFile = this.serialNumber.substring(i, i + 1).toLocaleLowerCase() !== '*' ? this.serialNumber.substring(i, i + 1).toLocaleLowerCase() : 'star';
      let scale = svgFile !== 'star' ? element.scale : .65;
      let x = svgFile !== 'star' ? serialLocationX[i] : serialLocationX[i] - 17;
      let y = svgFile !== 'star' ? element.y : element.y - 17;

      if (i === 0) {
        svg1.select("g#whole-dollar").append('image').attr("xlink:href", `../../../assets/img/digits/${svgFile}-white.svg`)
          .attr('width', '100')
          .attr('height', '100')
          .attr('class', `element-serial-bottom-left buck-element-serial`)
          .attr('id', `element-${element.type}`)
          .attr('transform', `translate(${x}, ${y}) scale(${scale})`);
      }

      svg1.select("g#whole-dollar").append('image').attr("xlink:href", `../../../assets/img/digits/${svgFile}.svg`)
        .attr('width', '100')
        .attr('height', '100')
        .attr('class', `element-serial-bottom-left buck-element-serial`)
        .attr('id', `element-${element.type}`)
        .attr('transform', `translate(${x}, ${y}) scale(${scale})`);
    }

    element = this.buckElements.find(element => element.type === BuckElementType.SERIAL_TOP_RIGHT);
    serialLocationX = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    serialLocationX[0] = element.x;
    serialLocationX[1] = serialLocationX[0] + 37;
    serialLocationX[2] = serialLocationX[1] + offset;
    serialLocationX[3] = serialLocationX[2] + offset;
    serialLocationX[4] = serialLocationX[3] + offset;
    serialLocationX[5] = serialLocationX[4] + offset;
    serialLocationX[6] = serialLocationX[5] + offset;
    serialLocationX[7] = serialLocationX[6] + offset;
    serialLocationX[8] = serialLocationX[7] + offset;
    serialLocationX[9] = serialLocationX[8] + 40;

    for (let i = 0; i < 10; i++) {
      let svgFile = this.serialNumber.substring(i, i + 1).toLocaleLowerCase() !== '*' ? this.serialNumber.substring(i, i + 1).toLocaleLowerCase() : 'star';
      let scale = svgFile !== 'star' ? element.scale : .65;
      let x = svgFile !== 'star' ? serialLocationX[i] : serialLocationX[i] - 17;
      let y = svgFile !== 'star' ? element.y : element.y - 17;

      if (i === 0) {
        svg1.select("g#whole-dollar").append('image').attr("xlink:href", `../../../assets/img/digits/${svgFile}-white.svg`)
          .attr('width', '100')
          .attr('height', '100')
          .attr('class', `element-serial-bottom-left buck-element-serial`)
          .attr('id', `element-${element.type}`)
          .attr('transform', `translate(${x}, ${y}) scale(${scale})`);
      }
      svg1.select("g#whole-dollar").append('image').attr("xlink:href", `../../../assets/img/digits/${svgFile}.svg`)
        .attr('width', '100')
        .attr('height', '100')
        .attr('class', `element-serial-bottom-left buck-element-serial`)
        .attr('id', `element-${element.type}`)
        .attr('transform', `translate(${x}, ${y}) scale(${scale})`);
    }

    //change color
    // console.log("asdf", document.getElementById('element-0'))
    // document.getElementById('element-0').setAttribute('style', 'filter: invert(10%) sepia(45%) saturate(7157%) hue-rotate(357deg) brightness(106%) contrast(117%)');

    this.svgBuckElements = d3.selectAll('.buck-element');
    this.svgBuckElements.on('click', event => this.onBuckElementClick(event, this));
  }

  appendBuckElement(svg: any, type: BuckElementType, fullFilePath: string) {
    let element = this.buckElements.find(element => element.type === type);
    svg.select("g#whole-dollar").append('image').attr("xlink:href", fullFilePath)
      .attr('width', '100')
      .attr('height', '100')
      .attr('id', `element-${element.type}`)
      .attr('class', `buck-element`)
      .attr('transform', `translate(${element.x}, ${element.y}) scale(${element.scale})`);
  }

  fadeElement(selector: string) {
    d3.selectAll(`${selector}`).transition().style("fill-opacity", "0.1");
  }

  darkenElement(selector: string) {
    d3.selectAll(`${selector}`).transition().style("fill-opacity", "1");
  }

  highlightElement(elementToHighlight: string) {
    d3.selectAll(`.buck-element`).transition(250).attr("opacity", "0.1").style('filter', `${this.NEUTRAL_FILTER}`);
    d3.selectAll(`.buck-element-serial`).transition(250).attr("opacity", "0.1");
    if (elementToHighlight === 'SERIAL_BOTTOM_LEFT' || elementToHighlight === 'SERIAL_TOP_RIGHT') {
      d3.select(`#element-${BuckElementType.SERIAL_BOTTOM_LEFT}`).transition(250).attr("opacity", "1");
      d3.select(`#element-${BuckElementType.SERIAL_TOP_RIGHT}`).transition(250).attr("opacity", "1");
    } else if (elementToHighlight === 'FED_NUMBER_TOP_LEFT' || elementToHighlight === 'FED_NUMBER_TOP_RIGHT' || elementToHighlight === 'FED_NUMBER_BOTTOM_LEFT' || elementToHighlight === 'FED_NUMBER_BOTTOM_RIGHT') {
      d3.select(`#element-${BuckElementType.FED_NUMBER_TOP_LEFT}`).transition(250).attr("opacity", "1");
      d3.select(`#element-${BuckElementType.FED_NUMBER_TOP_RIGHT}`).transition(250).attr("opacity", "1");
      d3.select(`#element-${BuckElementType.FED_NUMBER_BOTTOM_LEFT}`).transition(250).attr("opacity", "1");
      d3.select(`#element-${BuckElementType.FED_NUMBER_BOTTOM_RIGHT}`).transition(250).attr("opacity", "1");
    } else {
      d3.select(`#element-${BuckElementType[elementToHighlight]}`).transition(250).attr("opacity", "1");
    }
    this.fadeElement(`g#whole-dollar`);
    this.selectedElementType = BuckElementType[elementToHighlight];
    this.selectedBuckElement = this.buckElements.find(element => element.type === this.selectedElementType);
    const svg = d3.select("#dollar-svg");
    let x, y, scale;
    let offsetX = 0;
    let offsetY = 0;
    let defaultX = 2560;
    let screenX = window.innerWidth;
    let screenY = window.innerHeight;
    let constX = 220;
    let constY = 70;

    offsetX = ((2560 / screenX) * constX);
    offsetY = ((700 / screenY) * constY);


    console.log('window.innerWidth', window.innerWidth)
    console.log('window.innerHeight', window.innerHeight)
    x = this.selectedBuckElement.zoomX + offsetX;
    y = this.selectedBuckElement.zoomY + offsetY;
    scale = this.selectedBuckElement.zoomScale;

    svg.transition().duration(750).attr("transform", `translate(${x}, ${y}) scale(${scale})`);
  }

  resetBuck() {
    d3.selectAll(`.buck-element`).transition(250).attr("opacity", "1").style('filter', `${this.NEUTRAL_FILTER}`);
    d3.selectAll(`.buck-element-serial`).transition(250).attr("opacity", "1");

    const svg1 = d3.select("#dollar-svg")
    svg1.select(`g#whole-dollar`).transition().style("fill-opacity", "1");

    const svg = d3.select("#dollar-svg");
    svg.transition().duration(750).attr("transform", `translate(0, 0) scale(1)`);
    this.selectedElementType = null;
    this.selectedBuckElement = null;
  }

  getTransformParameters = (element) => {
    const transform = element.style.transform;
    let scale = 1,
      x = 0,
      y = 0;

    console.log(transform);
    if (transform.includes("scale"))
      scale = parseFloat(transform.slice(transform.indexOf("scale") + 6));
    if (transform.includes("translateX"))
      x = parseInt(transform.slice(transform.indexOf("translateX") + 11));
    if (transform.includes("translateY"))
      y = parseInt(transform.slice(transform.indexOf("translateY") + 11));

    return { scale, x, y };
  };

  onBuckElementClick(e, parent) {
    const elementId = e.srcElement.id;
    console.log('e', e);
    console.log('onBuckElementClick', elementId);
    const type = elementId.substring(8);
    parent.highlightBuckElement(+type);
  }

  highlightBuckElement(type: BuckElementType) {
    console.log('highlightBuckElement', type);
    console.log('highlightBuckElement[type]', BuckElementType[type]);
    this.selectedElementType = type;
    this.highlightElement(BuckElementType[type]);
    this.store.dispatch(TraceActions.setCurrentBuckElementType({ currentBuckElementType: type }));
    console.log("selectedElement", this.selectedElementType);
  }

  showDistrictInfo(): boolean {
    let rtn = false;

    rtn = this.selectedElementType === BuckElementType.SEAL
      || this.selectedElementType === BuckElementType.FED_NUMBER_BOTTOM_LEFT
      || this.selectedElementType === BuckElementType.FED_NUMBER_BOTTOM_RIGHT
      || this.selectedElementType === BuckElementType.FED_NUMBER_TOP_LEFT
      || this.selectedElementType === BuckElementType.FED_NUMBER_TOP_RIGHT
      || this.selectedElementType === BuckElementType.SERIAL_BOTTOM_LEFT
      || this.selectedElementType === BuckElementType.SERIAL_TOP_RIGHT
      && this.selectedDistrict
    return rtn;
  }

  navigateTo(whereTo: string) {
    this.store.dispatch(TraceActions.toggleShowSerialNumber());
    switch (whereTo) {
      case 'home':
        this.router.navigateByUrl('/');
        break;
      case 'buck':
        //this.router.navigateByUrl('/');
        break;
      case 'map':
        this.router.navigateByUrl(`/trace/${this.serialNumber}/about-fed-map`);
        break;
      default:
        break;
    }
  }

}

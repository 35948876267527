import { createReducer, on } from '@ngrx/store';
import { Constants } from 'src/app/shared/models/constant-models';
import * as AppState from '../../state/app.state';
import * as TraceActions from './trace.actions';
import { TraceState } from './trace.state';

export interface State extends AppState.State {
    trace: TraceState;
}

const initialState: TraceState = {
    currentSerialNumber: '',
    showBuck: false,
    showMap: true,
    currentBuckElementType: null,
    showSerialNumber: true,
    districtNumberFromRoute: 0,
    currentBuck: null,
    error: '',
    traceCount: -1
    // currentDistrictId: null,
    // currentDistrict: null,
    // districtList: Constants.districtList
}

export const traceReducer = createReducer<TraceState>(
    initialState,
    on(TraceActions.toggleShowSerialNumber, (state): TraceState => {
        console.log('original state: ' + JSON.stringify(state));
        return {
            ...state,
            showSerialNumber: !state.showSerialNumber
        };
    }),
    on(TraceActions.setSerialNumber, (state, action): TraceState => {
        return {
            ...state,
            currentSerialNumber: action.serialNumber
        }
    }),
    on(TraceActions.setCurrentBuckElementType, (state, action): TraceState => {
        return {
            ...state,
            currentBuckElementType: action.currentBuckElementType
        }
    }),
    // on(TraceActions.setCurrentDistrict, (state, action): TraceState => {
    //     return {
    //         ...state,
    //         currentDistrictId: action.currentDistrictId
    //     }
    // }),
    on(TraceActions.setCurrentBuckElementType, (state, action): TraceState => {
        return {
            ...state,
            currentBuckElementType: action.currentBuckElementType
        }
    }),
    on(TraceActions.setDistrictNumberFromRoute, (state, action): TraceState => {
        return {
            ...state,
            districtNumberFromRoute: action.districtNumberFromRoute
        }
    }),
    on(TraceActions.loadBuckSuccess, (state, action): TraceState => {
        return {
            ...state,
            currentBuck: action.currentBuck,
            traceCount: action.currentBuck.HIST?.length,
            error: ''
        }
    }),
    on(TraceActions.loadBuckFail, (state, action): TraceState => {
        return {
            ...state,
            currentBuck: null,
            error: action.error
        }
    }),
);
import { createAction, props } from "@ngrx/store"
import { Buck, BuckElementType } from "src/app/shared/models/buck.models"

// Step 1
export enum TraceActionTypes {
    SET_SERIAL_NUMBER = '[Trace] SET_SERIAL_NUMBER',
    TOGGLE_SHOW_SERIAL_NUMBER = '[Trace] TOGGLE_SHOW_SERIAL_NUMBER',
    GET_CURRENT_BUCK_ELEMENT_TYPE = '[Trace] GET_CURRENT_BUCK_ELEMENT_TYPE',
    SET_CURRENT_BUCK_ELEMENT_TYPE = '[Trace] SET_CURRENT_BUCK_ELEMENT_TYPE',
    SET_CURRENT_DISTRICT = '[Trace] SET_CURRENT_DISTRICT',
    SET_SHOW_BUCK = '[Trace] SET_SHOW_BUCK',
    SET_SHOW_MAP = '[Trace] SET_SHOW_MAP',
    GET_DISTRICT_NUMBER_FROM_ROUTE = '[Trace] GET_DISTRICT_NUMBER_FROM_ROUTE',
    SET_DISTRICT_NUMBER_FROM_ROUTE = '[Trace] SET_DISTRICT_NUMBER_FROM_ROUTE',
    LOAD_BUCK = '[Trace] LOAD_BUCK',
    LOAD_BUCK_SUCCESS = '[Trace] LOAD_BUCK_SUCCESS',
    LOAD_BUCK_FAIL = '[Trace] LOAD_BUCK_FAIL',
}

export const setSerialNumber = createAction(
    TraceActionTypes.SET_SERIAL_NUMBER,
    props<{ serialNumber: string }>()
)

export const toggleShowSerialNumber = createAction(
    TraceActionTypes.TOGGLE_SHOW_SERIAL_NUMBER
)

export const getCurrentBuckElementType = createAction(
    TraceActionTypes.GET_CURRENT_BUCK_ELEMENT_TYPE
)

export const setCurrentBuckElementType = createAction(
    TraceActionTypes.SET_CURRENT_BUCK_ELEMENT_TYPE,
    props<{ currentBuckElementType: BuckElementType }>()
)

export const setCurrentDistrict = createAction(
    TraceActionTypes.SET_CURRENT_DISTRICT,
    props<{ currentDistrictId: number }>()
)

export const setShowBuck = createAction(
    TraceActionTypes.SET_SHOW_BUCK,
    props<{ showBuck: boolean }>()
)

export const setShowMap = createAction(
    TraceActionTypes.SET_SHOW_MAP,
    props<{ showMap: boolean }>()
)

export const getDistrictNumberFromRoute = createAction(
    TraceActionTypes.GET_DISTRICT_NUMBER_FROM_ROUTE
)

export const setDistrictNumberFromRoute = createAction(
    TraceActionTypes.SET_DISTRICT_NUMBER_FROM_ROUTE,
    props<{ districtNumberFromRoute: number }>()
)

export const loadBuck = createAction(
    TraceActionTypes.LOAD_BUCK,
    props<{ serialNumber: string }>()
)

export const loadBuckSuccess = createAction(
    TraceActionTypes.LOAD_BUCK_SUCCESS,
    props<{ currentBuck: Buck }>()
)

export const loadBuckFail = createAction(
    TraceActionTypes.LOAD_BUCK_FAIL,
    props<{ error: string }>()
)



import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; import {
    FormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormArray,
    Validators,
} from '@angular/forms';
import '../../extensions/date';

@Component({
    templateUrl: './trace-detail.component.html',
    styleUrls: ['./trace-detail.component.scss']
})

export class TraceDetailComponent implements OnInit {
    private listOfValidSerials: string[] = [
        "D77722777H", "G05052008F", "A11111111*"
    ];

    sn: string = '';

    series: any[] = [
        {
            "Series": "2017A",
            "Year": 2017,
            "Secretary": "mnuchin",
            "SecretaryFullName": "Steven T. Mnuchin",
            "SecretaryNumber": "77th",
            "SecretaryTerm": "February 13, 2017 - Present",
            "SecretaryBio": "77th United States Secretary of the Treasury Assumed office February 13, 2017 - Present",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Steven_Mnuchin",
            "Treasurer": "carranza",
            "TreasurerFullName": "Jovita Carranza",
            "TreasurerNumber": "44th",
            "TreasurerTerm": "June 19, 2017 - Present",
            "TreasurerBio": "44th Treasurer of the United States Assumed office June 19, 2017 - Present",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Jovita_Carranza"
        },
        {
            "Series": "2017",
            "Year": 2017,
            "Secretary": "mnuchin",
            "SecretaryFullName": "Steven T. Mnuchin",
            "SecretaryNumber": "77th",
            "SecretaryTerm": "February 13, 2017 - Present",
            "SecretaryBio": "77th United States Secretary of the Treasury Assumed office February 13, 2017 - Present",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Steven_Mnuchin",
            "Treasurer": "carranza",
            "TreasurerFullName": "Jovita Carranza",
            "TreasurerNumber": "44th",
            "TreasurerTerm": "June 19, 2017 - Present",
            "TreasurerBio": "44th Treasurer of the United States Assumed office June 19, 2017 - Present",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Jovita_Carranza"
        },
        {
            "Series": "2013",
            "Year": 2013,
            "Secretary": "lew",
            "SecretaryFullName": "Jack J Lew",
            "SecretaryNumber": "76th",
            "SecretaryTerm": "February 28, 2013 – January 20, 2017",
            "SecretaryBio": "76th United States Secretary of the Treasury In office February 28, 2013 – January 20, 2017",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Jack_Lew",
            "Treasurer": "rios",
            "TreasurerFullName": "Rosa Gumataotao Rios",
            "TreasurerNumber": "43rd",
            "TreasurerTerm": "August 6, 2009 – July 8, 2016",
            "TreasurerBio": "43rd Treasurer of the United States In office August 6, 2009 – July 8, 2016",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosa_Gumataotao_Rios"
        },
        {
            "Series": "2009A",
            "Year": 2009,
            "Secretary": "geithner",
            "SecretaryFullName": "Timothy F. Geithner",
            "SecretaryNumber": "75th",
            "SecretaryTerm": "January 26, 2009 – January 25, 2013",
            "SecretaryBio": "75th United States Secretary of the Treasury In office January 26, 2009 – January 25, 2013",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Timothy_Geithner",
            "Treasurer": "rios",
            "TreasurerFullName": "Rosa Gumataotao Rios",
            "TreasurerNumber": "43rd",
            "TreasurerTerm": "August 6, 2009 – July 8, 2016",
            "TreasurerBio": "43rd Treasurer of the United States In office August 6, 2009 – July 8, 2016",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosa_Gumataotao_Rios"
        },
        {
            "Series": "2009",
            "Year": 2009,
            "Secretary": "geithner",
            "SecretaryFullName": "Timothy F. Geithner",
            "SecretaryNumber": "75th",
            "SecretaryTerm": "January 26, 2009 – January 25, 2013",
            "SecretaryBio": "75th United States Secretary of the Treasury In office January 26, 2009 – January 25, 2013",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Timothy_Geithner",
            "Treasurer": "rios",
            "TreasurerFullName": "Rosa Gumataotao Rios",
            "TreasurerNumber": "43rd",
            "TreasurerTerm": "August 6, 2009 – July 8, 2016",
            "TreasurerBio": "43rd Treasurer of the United States In office August 6, 2009 – July 8, 2016",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosa_Gumataotao_Rios"
        },
        {
            "Series": "2006A",
            "Year": 2006,
            "Secretary": "paulson",
            "SecretaryFullName": "Henry M. Paulson",
            "SecretaryNumber": "74th",
            "SecretaryTerm": "July 10, 2006 – January 20, 2009",
            "SecretaryBio": "74th United States Secretary of the Treasury In office July 10, 2006 – January 20, 2009",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Henry_Paulson",
            "Treasurer": "cabral",
            "TreasurerFullName": "Anna Escobedo Cabral",
            "TreasurerNumber": "42nd",
            "TreasurerTerm": "January 19, 2005 – January 20, 2009",
            "TreasurerBio": "42nd Treasurer of the United States In office January 19, 2005 – January 20, 2009",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Anna_Escobedo_Cabral"
        },
        {
            "Series": "2006",
            "Year": 2006,
            "Secretary": "paulson",
            "SecretaryFullName": "Henry M. Paulson",
            "SecretaryNumber": "74th",
            "SecretaryTerm": "July 10, 2006 – January 20, 2009",
            "SecretaryBio": "74th United States Secretary of the Treasury In office July 10, 2006 – January 20, 2009",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Henry_Paulson",
            "Treasurer": "cabral",
            "TreasurerFullName": "Anna Escobedo Cabral",
            "TreasurerNumber": "42nd",
            "TreasurerTerm": "January 19, 2005 – January 20, 2009",
            "TreasurerBio": "42nd Treasurer of the United States In office January 19, 2005 – January 20, 2009",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Anna_Escobedo_Cabral"
        },
        {
            "Series": "2004A",
            "Year": 2004,
            "Secretary": "snow",
            "SecretaryFullName": "John W Snow",
            "SecretaryNumber": "73rd",
            "SecretaryTerm": "February 3, 2003 – June 28, 2006",
            "SecretaryBio": "73rd United States Secretary of the Treasury In office February 3, 2003 – June 28, 2006",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_W._Snow",
            "Treasurer": "cabral",
            "TreasurerFullName": "Anna Escobedo Cabral",
            "TreasurerNumber": "42nd",
            "TreasurerTerm": "January 19, 2005 – January 20, 2009",
            "TreasurerBio": "42nd Treasurer of the United States In office January 19, 2005 – January 20, 2009",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Anna_Escobedo_Cabral"
        },
        {
            "Series": "2003A",
            "Year": 2003,
            "Secretary": "snow",
            "SecretaryFullName": "John W Snow",
            "SecretaryNumber": "73rd",
            "SecretaryTerm": "February 3, 2003 – June 28, 2006",
            "SecretaryBio": "73rd United States Secretary of the Treasury In office February 3, 2003 – June 28, 2006",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_W._Snow",
            "Treasurer": "cabral",
            "TreasurerFullName": "Anna Escobedo Cabral",
            "TreasurerNumber": "42nd",
            "TreasurerTerm": "January 19, 2005 – January 20, 2009",
            "TreasurerBio": "42nd Treasurer of the United States In office January 19, 2005 – January 20, 2009",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Anna_Escobedo_Cabral"
        },
        {
            "Series": "2004",
            "Year": 2004,
            "Secretary": "snow",
            "SecretaryFullName": "John W Snow",
            "SecretaryNumber": "73rd",
            "SecretaryTerm": "February 3, 2003 – June 28, 2006",
            "SecretaryBio": "73rd United States Secretary of the Treasury In office February 3, 2003 – June 28, 2006",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_W._Snow",
            "Treasurer": "marin",
            "TreasurerFullName": "Rosario Marin",
            "TreasurerNumber": "41st",
            "TreasurerTerm": "August 16, 2001 – June 30, 2003",
            "TreasurerBio": "41st Treasurer of the United States In office August 16, 2001 – June 30, 2003",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosario_Marin"
        },
        {
            "Series": "2003",
            "Year": 2003,
            "Secretary": "snow",
            "SecretaryFullName": "John W Snow",
            "SecretaryNumber": "73rd",
            "SecretaryTerm": "February 3, 2003 – June 28, 2006",
            "SecretaryBio": "73rd United States Secretary of the Treasury In office February 3, 2003 – June 28, 2006",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_W._Snow",
            "Treasurer": "marin",
            "TreasurerFullName": "Rosario Marin",
            "TreasurerNumber": "41st",
            "TreasurerTerm": "August 16, 2001 – June 30, 2003",
            "TreasurerBio": "41st Treasurer of the United States In office August 16, 2001 – June 30, 2003",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosario_Marin"
        },
        {
            "Series": "2001",
            "Year": 2001,
            "Secretary": "oneill",
            "SecretaryFullName": "Paul H O'Neill",
            "SecretaryNumber": "72nd",
            "SecretaryTerm": "January 20, 2001 – December 31, 2002",
            "SecretaryBio": "72nd United States Secretary of the Treasury In office January 20, 2001 – December 31, 2002",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Paul_H._O%27Neill",
            "Treasurer": "marin",
            "TreasurerFullName": "Rosario Marin",
            "TreasurerNumber": "41st",
            "TreasurerTerm": "August 16, 2001 – June 30, 2003",
            "TreasurerBio": "41st Treasurer of the United States In office August 16, 2001 – June 30, 2003",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosario_Marin"
        },
        {
            "Series": "1999",
            "Year": 1999,
            "Secretary": "summers",
            "SecretaryFullName": "Lawrence H. Summers",
            "SecretaryNumber": "71st",
            "SecretaryTerm": "July 2, 1999 – January 20, 2001",
            "SecretaryBio": "71st United States Secretary of the Treasury In office July 2, 1999 – January 20, 2001",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Lawrence_Summers",
            "Treasurer": "withrow",
            "TreasurerFullName": "Mary Ellen Withrow",
            "TreasurerNumber": "40th",
            "TreasurerTerm": "March 1, 1994 – January 20, 2001",
            "TreasurerBio": "40th Treasurer of the United States In office March 1, 1994 – January 20, 2001",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Mary_Ellen_Withrow"
        },
        {
            "Series": "1996",
            "Year": 1996,
            "Secretary": "rubin",
            "SecretaryFullName": "Robert E. Rubin",
            "SecretaryNumber": "70th",
            "SecretaryTerm": "January 11, 1995 – July 2, 1999",
            "SecretaryBio": "70th United States Secretary of the Treasury In office January 11, 1995 – July 2, 1999",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Robert_Rubin",
            "Treasurer": "withrow",
            "TreasurerFullName": "Mary Ellen Withrow",
            "TreasurerNumber": "40th",
            "TreasurerTerm": "March 1, 1994 – January 20, 2001",
            "TreasurerBio": "40th Treasurer of the United States In office March 1, 1994 – January 20, 2001",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Mary_Ellen_Withrow"
        },
        {
            "Series": "1995",
            "Year": 1995,
            "Secretary": "rubin",
            "SecretaryFullName": "Robert E. Rubin",
            "SecretaryNumber": "70th",
            "SecretaryTerm": "January 11, 1995 – July 2, 1999",
            "SecretaryBio": "70th United States Secretary of the Treasury In office January 11, 1995 – July 2, 1999",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Robert_Rubin",
            "Treasurer": "withrow",
            "TreasurerFullName": "Mary Ellen Withrow",
            "TreasurerNumber": "40th",
            "TreasurerTerm": "March 1, 1994 – January 20, 2001",
            "TreasurerBio": "40th Treasurer of the United States In office March 1, 1994 – January 20, 2001",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Mary_Ellen_Withrow"
        },
        {
            "Series": "1993",
            "Year": 1993,
            "Secretary": "bentsen",
            "SecretaryFullName": "Lloyd Bentsen",
            "SecretaryNumber": "69th",
            "SecretaryTerm": "January 20, 1993 – December 22, 1994",
            "SecretaryBio": "69th United States Secretary of the Treasury In office January 20, 1993 – December 22, 1994",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Lloyd_Bentsen",
            "Treasurer": "withrow",
            "TreasurerFullName": "Mary Ellen Withrow",
            "TreasurerNumber": "40th",
            "TreasurerTerm": "March 1, 1994 – January 20, 2001",
            "TreasurerBio": "40th Treasurer of the United States In office March 1, 1994 – January 20, 2001",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Mary_Ellen_Withrow"
        },
        {
            "Series": "1990",
            "Year": 1990,
            "Secretary": "brady",
            "SecretaryFullName": "Nicholas F. Brady",
            "SecretaryNumber": "68th",
            "SecretaryTerm": "September 15, 1988 – January 17, 1993",
            "SecretaryBio": "68th United States Secretary of the Treasury; In office September 15, 1988 – January 17, 1993",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Nicholas_F._Brady",
            "Treasurer": "villalpando",
            "TreasurerFullName": "Catalina Vasquez Villalpando",
            "TreasurerNumber": "39th",
            "TreasurerTerm": "December 11, 1989 – January 20, 1993",
            "TreasurerBio": "39th Treasurer of the United States In office December 11, 1989 – January 20, 1993",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Catalina_Vasquez_Villalpando"
        },
        {
            "Series": "1988A",
            "Year": 1988,
            "Secretary": "brady",
            "SecretaryFullName": "Nicholas F. Brady",
            "SecretaryNumber": "68th",
            "SecretaryTerm": "September 15, 1988 – January 17, 1993",
            "SecretaryBio": "68th United States Secretary of the Treasury; In office September 15, 1988 – January 17, 1993",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Nicholas_F._Brady",
            "Treasurer": "villalpando",
            "TreasurerFullName": "Catalina Vasquez Villalpando",
            "TreasurerNumber": "39th",
            "TreasurerTerm": "December 11, 1989 – January 20, 1993",
            "TreasurerBio": "39th Treasurer of the United States In office December 11, 1989 – January 20, 1993",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Catalina_Vasquez_Villalpando"
        },
        {
            "Series": "1988",
            "Year": 1988,
            "Secretary": "brady",
            "SecretaryFullName": "Nicholas F. Brady",
            "SecretaryNumber": "68th",
            "SecretaryTerm": "September 15, 1988 – January 17, 1993",
            "SecretaryBio": "68th United States Secretary of the Treasury; In office September 15, 1988 – January 17, 1993",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Nicholas_F._Brady",
            "Treasurer": "ortega",
            "TreasurerFullName": "Katherine Davalos Ortega",
            "TreasurerNumber": "38th",
            "TreasurerTerm": "September 26, 1983 – July 1, 1989",
            "TreasurerBio": "38th Treasurer of the United States In office September 26, 1983 – July 1, 1989",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Katherine_D._Ortega"
        },
        {
            "Series": "1985",
            "Year": 1985,
            "Secretary": "baker",
            "SecretaryFullName": "James A. Baker III",
            "SecretaryNumber": "67th",
            "SecretaryTerm": "February 4, 1985 – August 17, 1988",
            "SecretaryBio": "67th United States Secretary of the Treasury In office February 4, 1985 – August 17, 1988",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/James_Baker",
            "Treasurer": "ortega",
            "TreasurerFullName": "Katherine Davalos Ortega",
            "TreasurerNumber": "38th",
            "TreasurerTerm": "September 26, 1983 – July 1, 1989",
            "TreasurerBio": "38th Treasurer of the United States In office September 26, 1983 – July 1, 1989",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Katherine_D._Ortega"
        },
        {
            "Series": "1981A",
            "Year": 1981,
            "Secretary": "regan",
            "SecretaryFullName": "Donald T. Regan",
            "SecretaryNumber": "66th",
            "SecretaryTerm": "January 22, 1981 – February 1, 1985",
            "SecretaryBio": "66th United States Secretary of the Treasury In office January 22, 1981 – February 1, 1985",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Donald_Regan",
            "Treasurer": "ortega",
            "TreasurerFullName": "Katherine Davalos Ortega",
            "TreasurerNumber": "38th",
            "TreasurerTerm": "September 26, 1983 – July 1, 1989",
            "TreasurerBio": "38th Treasurer of the United States In office September 26, 1983 – July 1, 1989",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Katherine_D._Ortega"
        },
        {
            "Series": "1981",
            "Year": 1981,
            "Secretary": "regan",
            "SecretaryFullName": "Donald T. Regan",
            "SecretaryNumber": "66th",
            "SecretaryTerm": "January 22, 1981 – February 1, 1985",
            "SecretaryBio": "66th United States Secretary of the Treasury In office January 22, 1981 – February 1, 1985",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Donald_Regan",
            "Treasurer": "buchanan",
            "TreasurerFullName": "Angela M Buchanan",
            "TreasurerNumber": "37th",
            "TreasurerTerm": "March 20, 1981 – July 5, 1983",
            "TreasurerBio": "37th Treasurer of the United States In office March 20, 1981 – July 5, 1983",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Bay_Buchanan"
        },
        {
            "Series": "1977A",
            "Year": 1977,
            "Secretary": "miller",
            "SecretaryFullName": "G. William Miller",
            "SecretaryNumber": "65th",
            "SecretaryTerm": "August 6, 1979 – January 20, 1981",
            "SecretaryBio": "65th United States Secretary of the Treasury In office August 6, 1979 – January 20, 1981",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/G._William_Miller",
            "Treasurer": "morton",
            "TreasurerFullName": "Azie Taylor Morton",
            "TreasurerNumber": "36th",
            "TreasurerTerm": "September 12, 1977 – January 20, 1981",
            "TreasurerBio": "36th Treasurer of the United States In office September 12, 1977 – January 20, 1981",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Azie_Taylor_Morton"
        },
        {
            "Series": "1977",
            "Year": 1977,
            "Secretary": "blumenthal",
            "SecretaryFullName": "W M Blumenthal",
            "SecretaryNumber": "64th",
            "SecretaryTerm": "January 23, 1977 – August 4, 1979",
            "SecretaryBio": "64th United States Secretary of the Treasury In office January 23, 1977 – August 4, 1979",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/W._Michael_Blumenthal",
            "Treasurer": "morton",
            "TreasurerFullName": "Azie Taylor Morton",
            "TreasurerNumber": "36th",
            "TreasurerTerm": "September 12, 1977 – January 20, 1981",
            "TreasurerBio": "36th Treasurer of the United States In office September 12, 1977 – January 20, 1981",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Azie_Taylor_Morton"
        },
        {
            "Series": "1974",
            "Year": 1974,
            "Secretary": "simon",
            "SecretaryFullName": "William E. Simon",
            "SecretaryNumber": "63rd",
            "SecretaryTerm": "May 9, 1974 – January 20, 1977",
            "SecretaryBio": "63rd United States Secretary of the Treasury In office May 9, 1974 – January 20, 1977",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/William_E._Simon",
            "Treasurer": "neff",
            "TreasurerFullName": "Francine I. Neff",
            "TreasurerNumber": "35th",
            "TreasurerTerm": "June 23, 1974 – January 20, 1977",
            "TreasurerBio": "35th Treasurer of the United States In office June 23, 1974 – January 20, 1977",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Francine_Irving_Neff"
        },
        {
            "Series": "1969D",
            "Year": 1969,
            "Secretary": "shultz",
            "SecretaryFullName": "George P. Shultz",
            "SecretaryNumber": "62nd",
            "SecretaryTerm": "June 12, 1972 – May 8, 1974",
            "SecretaryBio": "62nd United States Secretary of the Treasury In office June 12, 1972 – May 8, 1974",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/George_P._Shultz",
            "Treasurer": "banuelos",
            "TreasurerFullName": "Romana Acosta Bañuelos",
            "TreasurerNumber": "34th",
            "TreasurerTerm": "December 17, 1971 – February 14, 1974",
            "TreasurerBio": "34th Treasurer of the United States In office December 17, 1971 – February 14, 1974",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Romana_Acosta_Ba%C3%B1uelos"
        },
        {
            "Series": "1969C",
            "Year": 1969,
            "Secretary": "connally",
            "SecretaryFullName": "John B. Connally",
            "SecretaryNumber": "61st",
            "SecretaryTerm": "February 11, 1971 – June 12, 1972",
            "SecretaryBio": "61st United States Secretary of the Treasury In office February 11, 1971 – June 12, 1972",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_Connally",
            "Treasurer": "banuelos",
            "TreasurerFullName": "Romana Acosta Bañuelos",
            "TreasurerNumber": "34th",
            "TreasurerTerm": "December 17, 1971 – February 14, 1974",
            "TreasurerBio": "34th Treasurer of the United States In office December 17, 1971 – February 14, 1974",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Romana_Acosta_Ba%C3%B1uelos"
        },
        {
            "Series": "1969B",
            "Year": 1969,
            "Secretary": "connally",
            "SecretaryFullName": "John B. Connally",
            "SecretaryNumber": "61st",
            "SecretaryTerm": "February 11, 1971 – June 12, 1972",
            "SecretaryBio": "61st United States Secretary of the Treasury In office February 11, 1971 – June 12, 1972",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_Connally",
            "Treasurer": "kabis",
            "TreasurerFullName": "Dorothy Andrews Kabis",
            "TreasurerNumber": "33rd",
            "TreasurerTerm": "May 8, 1969 – July 3, 1971",
            "TreasurerBio": "33rd Treasurer of the United States In office May 8, 1969 – July 3, 1971",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Dorothy_Andrews_Elston_Kabis"
        },
        {
            "Series": "1969A",
            "Year": 1969,
            "Secretary": "kennedy",
            "SecretaryFullName": "David M Kennedy",
            "SecretaryNumber": "60th",
            "SecretaryTerm": "January 22, 1969 – February 11, 1971",
            "SecretaryBio": "60th United States Secretary of the Treasury In office January 22, 1969 – February 11, 1971",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/David_M._Kennedy",
            "Treasurer": "kabis",
            "TreasurerFullName": "Dorothy Andrews Kabis",
            "TreasurerNumber": "33rd",
            "TreasurerTerm": "May 8, 1969 – July 3, 1971",
            "TreasurerBio": "33rd Treasurer of the United States In office May 8, 1969 – July 3, 1971",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Dorothy_Andrews_Elston_Kabis"
        },
        {
            "Series": "1969",
            "Year": 1969,
            "Secretary": "kennedy",
            "SecretaryFullName": "David M Kennedy",
            "SecretaryNumber": "60th",
            "SecretaryTerm": "January 22, 1969 – February 11, 1971",
            "SecretaryBio": "60th United States Secretary of the Treasury In office January 22, 1969 – February 11, 1971",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/David_M._Kennedy",
            "Treasurer": "elston",
            "TreasurerFullName": "Dorothy Andrews Elston",
            "TreasurerNumber": "33rd",
            "TreasurerTerm": "May 8, 1969 – July 3, 1971",
            "TreasurerBio": "33rd Treasurer of the United States In office May 8, 1969 – July 3, 1971",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Dorothy_Andrews_Elston_Kabis"
        },
        {
            "Series": "1963B",
            "Year": 1963,
            "Secretary": "barr",
            "SecretaryFullName": "Joseph W. Barr",
            "SecretaryNumber": "59th",
            "SecretaryTerm": "December 21, 1968 – January 20, 1969",
            "SecretaryBio": "59th United States Secretary of the Treasury In office December 21, 1968 – January 20, 1969",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Joseph_W._Barr",
            "Treasurer": "granahan",
            "TreasurerFullName": "Kathryn O'Hay Granahan",
            "TreasurerNumber": "32nd",
            "TreasurerTerm": "January 9, 1963 – November 22, 1966",
            "TreasurerBio": "32nd Treasurer of the United States In office January 9, 1963 – November 22, 1966",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Kathryn_E._Granahan"
        },
        {
            "Series": "1963A",
            "Year": 1963,
            "Secretary": "fowler",
            "SecretaryFullName": "Henry H. Fowler",
            "SecretaryNumber": "58th",
            "SecretaryTerm": "April 1, 1965 – December 20, 1968",
            "SecretaryBio": "58th United States Secretary of the Treasury In office April 1, 1965 – December 20, 1968",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Henry_H._Fowler",
            "Treasurer": "granahan",
            "TreasurerFullName": "Kathryn O'Hay Granahan",
            "TreasurerNumber": "32nd",
            "TreasurerTerm": "January 9, 1963 – November 22, 1966",
            "TreasurerBio": "32nd Treasurer of the United States In office January 9, 1963 – November 22, 1966",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Kathryn_E._Granahan"
        },
        {
            "Series": "1963",
            "Year": 1963,
            "Secretary": "dillon",
            "SecretaryFullName": "C. Douglas Dillon",
            "SecretaryNumber": "57th",
            "SecretaryTerm": "January 21, 1961 – April 1, 1965",
            "SecretaryBio": "57th United States Secretary of the Treasury In office January 21, 1961 – April 1, 1965",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/C._Douglas_Dillon",
            "Treasurer": "granahan",
            "TreasurerFullName": "Kathryn O'Hay Granahan",
            "TreasurerNumber": "32nd",
            "TreasurerTerm": "January 9, 1963 – November 22, 1966",
            "TreasurerBio": "32nd Treasurer of the United States In office January 9, 1963 – November 22, 1966",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Kathryn_E._Granahan"
        }
    ];


    buckForm = new UntypedFormGroup({
        serialNumber: new UntypedFormControl('', [
            Validators.minLength(10),
            Validators.required,
        ]),
        seriesList: new UntypedFormControl(this.series[2].Series),
    });

    constructor(private route: ActivatedRoute) { }


    ngOnInit() {
        //add + makes it a number
        this.sn = this.route.snapshot.paramMap.get('sn');
    }

    submitSerial() {

    }



}


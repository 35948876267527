<!DOCTYPE html>
<html>

<head>
    <title>trace some bucks</title>
    <!-- Latest compiled and minified CSS -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">

</head>

<body>
    <form class="initial" [formGroup]="buckForm" (submit)="submitSerial()">
        <div class="serial-entry-container">
            <div class="serial-entry-inner">
                <div class="serial-entry">
                    <div class="serial-header">
                        <h1>serial number from route: {{sn}}</h1>
                    </div>
                    <!-- <div class="serial-header" [hidden]="mySerialState!=serialState.ValidExists">
                        We traced your Buck ->
                    </div>
                    <div class="serial-header" [hidden]="mySerialState!=serialState.ValidNotExist && mySerialState!=serialState.Valid">
                        Let's add more details ->
                    </div>
                    <div class="serial-header" [hidden]="mySerialState!=serialState.Invalid && mySerialState!=serialState.ReEnter">
                        Invalid Serial. Please re-enter
                    </div> -->
                    <div>
                        <input formControlName="serialNumber" type="text" class="initial-serial"
                            placeholder="enter a buck" (keydown.enter)="submitSerial();"
                            pattern="^[a-lA-L]{1}\d{8}[a-np-yA-NP-Y*]{1}$" />
                    </div>
                    <div style="color:peru;">
                        Use (*) for star
                    </div>
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>Series (year)</mat-label>
                            <mat-select formControlName="seriesList">
                                <mat-option *ngFor="let s of series" [value]="s.Series">
                                    {{ s.Series }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <button class="buck-button" type="submit">Trace</button>
                    </div>
                </div>
            </div>
        </div>
    </form>


</body>

</html>
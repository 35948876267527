import { Injectable, Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Buck } from 'src/app/shared/models/buck.models';
import { map, catchError } from 'rxjs/operators';



@Injectable()
export class TraceService {
    urlRoot: string = location.href.indexOf('localhost:') > -1 ? 'http://localhost:47777/' : 'https://bucktrace.com/';
    svcRoot: string = location.href.indexOf('localhost:') > -1 ? 'http://localhost:57777/api/' : 'https://api.bucktrace.com/';
    constructor(private http: HttpClient) { }

    getBuck(SN: string): Observable<Buck> {
        return of(this.b);
    };

    // getBuck(SN: string): Observable<Buck> {
    //     let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Amz-Security-Token': '274DE416B18D804FBBB266056DB921BBDD5473349D4B1C43D08D3835FEBF75F2258BDAD17C1EE24E9496D6F099A48EEA5F2789037B807241D5B5CAAAFA8F5F169A3ACB435F0C6B45DEBD721F159C21FD2E1FF84037F4784981BEAAAC30E3A7305827' });
    //     this.svcRoot = 'https://api.bucktrace.com/';
    //     let url = this.svcRoot + 'buck/' + SN;

    //     return this.http.get<Buck>(url, {
    //         headers: headers
    //     }).pipe(map(response => response));
    // };

    // getBucks(userId: string): Observable<Array<Buck>> {
    //     let headers = new Headers({ 'Content-Type': 'application/json' });
    //     let options = new RequestOptions({ headers: headers });
    //     let url = this.svcRoot + 'bucks/' + userId;
    //     return this.http.get(url, options)
    //         .map(response => response.json())
    //         .catch(this.handleErrorObservable);
    // };

    // getAllBucks(days: number): Observable<Array<Buck>> {
    //     let headers = new Headers({ 'Content-Type': 'application/json' });
    //     let options = new RequestOptions({ headers: headers });
    //     let url = this.svcRoot + 'bucks/getall/' + days;
    //     return this.http.get(url, options)
    //         .map(response => response.json())
    //         .catch(this.handleErrorObservable);
    // };

    b: Buck = {
        "SN": "K15163214D",
        "SD": null,
        "FRD": 0,
        "DN": 0,
        "FRL": "\u0000",
        "PX": "\u0000",
        "SX": "\u0000",
        "FW": false,
        "HIST": [
            {
                "Lat": 32.791223,
                "Long": -96.800954,
                "UserId": 1,
                "City": "Dallas",
                "State": "TX",
                "Zip": "75201",
                "FullAddress": null,
                "Name": "Federal Reserve Bank of Dallas",
                "TraceDate": new Date("1993-07-27T00:00:00.000Z"),
                "Photo": null,
                "Address1": "2200 N Pearl St",
                "FromAPI": false,
                "Address2": "",
            },
            {
                "Lat": 32.9427502855021,
                "Long": -97.1172928188059,
                "UserId": 1,
                "City": "Southlake",
                "State": "TX",
                "Zip": "76092",
                "FullAddress": null,
                "Name": null,
                "TraceDate": new Date("2022-06-03T17:49:50.114Z"),
                "Photo": null,
                "Address1": "32.94275028550211, US",
                "FromAPI": false
            }
        ],
        "SRS": null,
        "BEP": 0,
        "CDT": null,
        "UDT": null,
        "MAT": {
            "IsAllPairs2": false,
            "IsAllPairs4": false,
            "IsRepeatingPairs2": false,
            "IsRepeatingPairs4": false,
            "IsConsectutiveAscending": false,
            "IsConsectutiveDescending": false,
            "IsConsectutivePairAscending": false,
            "IsConsectutivePairDescending": false,
            "IsUniqueDigits": false,
            "IsOneDigit": false,
            "IsTwoDigits": false,
            "IsSixOrMoreSameDigit": false,
            "IsPalindrome": false,
            "IsDate": false
        },
        "DBMAT": false,
        "Errors": null,
        "B64_CAP": null,
        "DSRSCT": 0,
        "DSTCITY": null,
        "CA": false
    }
}
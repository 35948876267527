import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TraceState } from './trace.state';
const getTraceFeatureState = createFeatureSelector<TraceState>('trace');

export const getShowSerialNumber = createSelector(
    getTraceFeatureState,
    state => state.showSerialNumber
);

export const getCurrentSerialNumber = createSelector(
    getTraceFeatureState,
    state => state.currentSerialNumber.toLocaleUpperCase()
);

export const getShowBuck = createSelector(
    getTraceFeatureState,
    state => state.showBuck
);

export const getShowMap = createSelector(
    getTraceFeatureState,
    state => state.showMap
);

export const getCurrentBuck = createSelector(
    getTraceFeatureState,
    state => state.currentBuck
);

export const getCurrentBuckElementType = createSelector(
    getTraceFeatureState,
    state => state.currentBuckElementType
);

export const getDistrictNumberFromRoute = createSelector(
    getTraceFeatureState,
    state => state.districtNumberFromRoute
);

export const getTraceCount = createSelector(
    getTraceFeatureState,
    state => state.traceCount
);

export const getError = createSelector(
    getTraceFeatureState,
    state => state.error
);

// export const getCurrentDistrictId = createSelector(
//     getTraceFeatureState,
//     state => state.currentDistrictId
// );

// export const getCurrentDistrict = createSelector(
//     getTraceFeatureState,
//     getCurrentDistrictId,
//     (state, currentDistrictId) => {
//         if (currentDistrictId === 0) {
//             return null;
//         } else {
//             return currentDistrictId ? state.districtList.find(d => d.districtId === currentDistrictId) : null;
//         }
//     }
// );


import { District } from "./district.models";

export class Constants {
    static states: any = {
        "AL": "Alabama",
        "AK": "Alaska",
        "AS": "American Samoa",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District Of Columbia",
        "FM": "Federated States Of Micronesia",
        "FL": "Florida",
        "GA": "Georgia",
        "GU": "Guam",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MH": "Marshall Islands",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "MP": "Northern Mariana Islands",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PW": "Palau",
        "PA": "Pennsylvania",
        "PR": "Puerto Rico",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VI": "Virgin Islands",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
    }

    static banks: any = [
        {
            district: 1,
            districtName: "1st",
            prefix: "A",
            name: "Federal Reserve Bank of Boston",
            address1: "600 Atlantic Ave",
            address2: "",
            city: "Boston",
            state: "MA",
            zip: "02210",
            lat: 42.353158,
            long: -71.054608
        },
        {
            district: 2,
            districtName: "2nd",
            prefix: "B",
            name: "Federal Reserve Bank of New York",
            address1: "33 Liberty St",
            address2: "",
            city: "New York",
            state: "NY",
            zip: "10045",
            lat: 40.708178,
            long: -74.008813
        },
        {
            district: 3,
            districtName: "3rd",
            prefix: "C",
            name: "Federal Reserve Bank of Philidelphia",
            address1: "10 N Independence Mall W",
            address2: "",
            city: "Philadelphia",
            state: "PA",
            zip: "19106",
            lat: 39.953268,
            long: -75.149827
        },
        {
            district: 4,
            districtName: "4th",
            prefix: "D",
            name: "Federal Reserve Bank of Cleveland",
            address1: "1455 E 6th St",
            address2: "",
            city: "Cleveland",
            state: "OH",
            zip: "44114",
            lat: 41.502007,
            long: -81.690881
        },
        {
            district: 5,
            districtName: "5th",
            prefix: "E",
            name: "Federal Reserve Bank of Richmond",
            address1: "701 E Byrd St",
            address2: "",
            city: "Richmond",
            state: "VA",
            zip: "23219",
            lat: 37.536675,
            long: -77.440533
        },
        {
            district: 6,
            districtName: "6th",
            prefix: "F",
            name: "Federal Reserve Bank of Atlanta",
            address1: "1000 Peachtree St NE",
            address2: "",
            city: "Atlanta",
            state: "GA",
            zip: "30309",
            lat: 33.782569,
            long: -84.383940
        },
        {
            district: 7,
            districtName: "7th",
            prefix: "G",
            name: "Federal Reserve Bank of Chicago",
            address1: "230 S LaSalle St",
            address2: "",
            city: "Chicago",
            state: "IL",
            zip: "60604",
            lat: 41.878320,
            long: -87.63230413123
        },
        {
            district: 8,
            districtName: "8th",
            prefix: "H",
            name: "Federal Reserve Bank of St. Louis",
            address1: "Federal Reserve Bank Plaza",
            address2: "1 Broadway",
            city: "St. Louis",
            state: "MO",
            zip: "63102",
            lat: 38.628285,
            long: -90.188007
        },
        {
            district: 9,
            districtName: "9th",
            prefix: "I",
            name: "Federal Reserve Bank of Minneapolis",
            address1: "90 Hennepin Ave",
            address2: "",
            city: "Minneapolis",
            state: "MN",
            zip: "55401",
            lat: 44.984256,
            long: -93.266220
        },
        {
            district: 10,
            districtName: "10th",
            prefix: "J",
            name: "Federal Reserve Bank of Kansas City",
            address1: "1 Memorial Dr",
            address2: "",
            city: "Kansas City",
            state: "MO",
            zip: "64198",
            lat: 39.076992,
            long: -94.586129
        },
        {
            district: 11,
            districtName: "11th",
            prefix: "K",
            name: "Federal Reserve Bank of Dallas",
            address1: "2200 N Pearl St",
            address2: "",
            city: "Dallas",
            state: "TX",
            zip: "75201",
            lat: 32.791223,
            long: -96.800954
        },
        {
            district: 12,
            districtName: "12th",
            prefix: "L",
            name: "Federal Reserve Bank of San Francisco",
            address1: "101 Market St",
            address2: "",
            city: "San Francisco",
            state: "CA",
            zip: "94105",
            lat: 37.793292,
            long: -122.396317
        }
    ];

    static series: any[] = [
        {
            "Series": "2017A",
            "Year": 2017,
            "Secretary": "mnuchin",
            "SecretaryFullName": "Steven T. Mnuchin",
            "SecretaryNumber": "77th",
            "SecretaryTerm": "February 13, 2017 - Present",
            "SecretaryBio": "77th United States Secretary of the Treasury Assumed office February 13, 2017 - Present",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Steven_Mnuchin",
            "Treasurer": "carranza",
            "TreasurerFullName": "Jovita Carranza",
            "TreasurerNumber": "44th",
            "TreasurerTerm": "June 19, 2017 - Present",
            "TreasurerBio": "44th Treasurer of the United States Assumed office June 19, 2017 - Present",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Jovita_Carranza"
        },
        {
            "Series": "2017",
            "Year": 2017,
            "Secretary": "mnuchin",
            "SecretaryFullName": "Steven T. Mnuchin",
            "SecretaryNumber": "77th",
            "SecretaryTerm": "February 13, 2017 - Present",
            "SecretaryBio": "77th United States Secretary of the Treasury Assumed office February 13, 2017 - Present",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Steven_Mnuchin",
            "Treasurer": "carranza",
            "TreasurerFullName": "Jovita Carranza",
            "TreasurerNumber": "44th",
            "TreasurerTerm": "June 19, 2017 - Present",
            "TreasurerBio": "44th Treasurer of the United States Assumed office June 19, 2017 - Present",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Jovita_Carranza"
        },
        {
            "Series": "2013",
            "Year": 2013,
            "Secretary": "lew",
            "SecretaryFullName": "Jack J Lew",
            "SecretaryNumber": "76th",
            "SecretaryTerm": "February 28, 2013 – January 20, 2017",
            "SecretaryBio": "76th United States Secretary of the Treasury In office February 28, 2013 – January 20, 2017",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Jack_Lew",
            "Treasurer": "rios",
            "TreasurerFullName": "Rosa Gumataotao Rios",
            "TreasurerNumber": "43rd",
            "TreasurerTerm": "August 6, 2009 – July 8, 2016",
            "TreasurerBio": "43rd Treasurer of the United States In office August 6, 2009 – July 8, 2016",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosa_Gumataotao_Rios"
        },
        {
            "Series": "2009A",
            "Year": 2009,
            "Secretary": "geithner",
            "SecretaryFullName": "Timothy F. Geithner",
            "SecretaryNumber": "75th",
            "SecretaryTerm": "January 26, 2009 – January 25, 2013",
            "SecretaryBio": "75th United States Secretary of the Treasury In office January 26, 2009 – January 25, 2013",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Timothy_Geithner",
            "Treasurer": "rios",
            "TreasurerFullName": "Rosa Gumataotao Rios",
            "TreasurerNumber": "43rd",
            "TreasurerTerm": "August 6, 2009 – July 8, 2016",
            "TreasurerBio": "43rd Treasurer of the United States In office August 6, 2009 – July 8, 2016",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosa_Gumataotao_Rios"
        },
        {
            "Series": "2009",
            "Year": 2009,
            "Secretary": "geithner",
            "SecretaryFullName": "Timothy F. Geithner",
            "SecretaryNumber": "75th",
            "SecretaryTerm": "January 26, 2009 – January 25, 2013",
            "SecretaryBio": "75th United States Secretary of the Treasury In office January 26, 2009 – January 25, 2013",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Timothy_Geithner",
            "Treasurer": "rios",
            "TreasurerFullName": "Rosa Gumataotao Rios",
            "TreasurerNumber": "43rd",
            "TreasurerTerm": "August 6, 2009 – July 8, 2016",
            "TreasurerBio": "43rd Treasurer of the United States In office August 6, 2009 – July 8, 2016",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosa_Gumataotao_Rios"
        },
        {
            "Series": "2006A",
            "Year": 2006,
            "Secretary": "paulson",
            "SecretaryFullName": "Henry M. Paulson",
            "SecretaryNumber": "74th",
            "SecretaryTerm": "July 10, 2006 – January 20, 2009",
            "SecretaryBio": "74th United States Secretary of the Treasury In office July 10, 2006 – January 20, 2009",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Henry_Paulson",
            "Treasurer": "cabral",
            "TreasurerFullName": "Anna Escobedo Cabral",
            "TreasurerNumber": "42nd",
            "TreasurerTerm": "January 19, 2005 – January 20, 2009",
            "TreasurerBio": "42nd Treasurer of the United States In office January 19, 2005 – January 20, 2009",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Anna_Escobedo_Cabral"
        },
        {
            "Series": "2006",
            "Year": 2006,
            "Secretary": "paulson",
            "SecretaryFullName": "Henry M. Paulson",
            "SecretaryNumber": "74th",
            "SecretaryTerm": "July 10, 2006 – January 20, 2009",
            "SecretaryBio": "74th United States Secretary of the Treasury In office July 10, 2006 – January 20, 2009",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Henry_Paulson",
            "Treasurer": "cabral",
            "TreasurerFullName": "Anna Escobedo Cabral",
            "TreasurerNumber": "42nd",
            "TreasurerTerm": "January 19, 2005 – January 20, 2009",
            "TreasurerBio": "42nd Treasurer of the United States In office January 19, 2005 – January 20, 2009",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Anna_Escobedo_Cabral"
        },
        {
            "Series": "2004A",
            "Year": 2004,
            "Secretary": "snow",
            "SecretaryFullName": "John W Snow",
            "SecretaryNumber": "73rd",
            "SecretaryTerm": "February 3, 2003 – June 28, 2006",
            "SecretaryBio": "73rd United States Secretary of the Treasury In office February 3, 2003 – June 28, 2006",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_W._Snow",
            "Treasurer": "cabral",
            "TreasurerFullName": "Anna Escobedo Cabral",
            "TreasurerNumber": "42nd",
            "TreasurerTerm": "January 19, 2005 – January 20, 2009",
            "TreasurerBio": "42nd Treasurer of the United States In office January 19, 2005 – January 20, 2009",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Anna_Escobedo_Cabral"
        },
        {
            "Series": "2003A",
            "Year": 2003,
            "Secretary": "snow",
            "SecretaryFullName": "John W Snow",
            "SecretaryNumber": "73rd",
            "SecretaryTerm": "February 3, 2003 – June 28, 2006",
            "SecretaryBio": "73rd United States Secretary of the Treasury In office February 3, 2003 – June 28, 2006",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_W._Snow",
            "Treasurer": "cabral",
            "TreasurerFullName": "Anna Escobedo Cabral",
            "TreasurerNumber": "42nd",
            "TreasurerTerm": "January 19, 2005 – January 20, 2009",
            "TreasurerBio": "42nd Treasurer of the United States In office January 19, 2005 – January 20, 2009",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Anna_Escobedo_Cabral"
        },
        {
            "Series": "2004",
            "Year": 2004,
            "Secretary": "snow",
            "SecretaryFullName": "John W Snow",
            "SecretaryNumber": "73rd",
            "SecretaryTerm": "February 3, 2003 – June 28, 2006",
            "SecretaryBio": "73rd United States Secretary of the Treasury In office February 3, 2003 – June 28, 2006",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_W._Snow",
            "Treasurer": "marin",
            "TreasurerFullName": "Rosario Marin",
            "TreasurerNumber": "41st",
            "TreasurerTerm": "August 16, 2001 – June 30, 2003",
            "TreasurerBio": "41st Treasurer of the United States In office August 16, 2001 – June 30, 2003",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosario_Marin"
        },
        {
            "Series": "2003",
            "Year": 2003,
            "Secretary": "snow",
            "SecretaryFullName": "John W Snow",
            "SecretaryNumber": "73rd",
            "SecretaryTerm": "February 3, 2003 – June 28, 2006",
            "SecretaryBio": "73rd United States Secretary of the Treasury In office February 3, 2003 – June 28, 2006",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_W._Snow",
            "Treasurer": "marin",
            "TreasurerFullName": "Rosario Marin",
            "TreasurerNumber": "41st",
            "TreasurerTerm": "August 16, 2001 – June 30, 2003",
            "TreasurerBio": "41st Treasurer of the United States In office August 16, 2001 – June 30, 2003",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosario_Marin"
        },
        {
            "Series": "2001",
            "Year": 2001,
            "Secretary": "oneill",
            "SecretaryFullName": "Paul H O'Neill",
            "SecretaryNumber": "72nd",
            "SecretaryTerm": "January 20, 2001 – December 31, 2002",
            "SecretaryBio": "72nd United States Secretary of the Treasury In office January 20, 2001 – December 31, 2002",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Paul_H._O%27Neill",
            "Treasurer": "marin",
            "TreasurerFullName": "Rosario Marin",
            "TreasurerNumber": "41st",
            "TreasurerTerm": "August 16, 2001 – June 30, 2003",
            "TreasurerBio": "41st Treasurer of the United States In office August 16, 2001 – June 30, 2003",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Rosario_Marin"
        },
        {
            "Series": "1999",
            "Year": 1999,
            "Secretary": "summers",
            "SecretaryFullName": "Lawrence H. Summers",
            "SecretaryNumber": "71st",
            "SecretaryTerm": "July 2, 1999 – January 20, 2001",
            "SecretaryBio": "71st United States Secretary of the Treasury In office July 2, 1999 – January 20, 2001",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Lawrence_Summers",
            "Treasurer": "withrow",
            "TreasurerFullName": "Mary Ellen Withrow",
            "TreasurerNumber": "40th",
            "TreasurerTerm": "March 1, 1994 – January 20, 2001",
            "TreasurerBio": "40th Treasurer of the United States In office March 1, 1994 – January 20, 2001",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Mary_Ellen_Withrow"
        },
        {
            "Series": "1996",
            "Year": 1996,
            "Secretary": "rubin",
            "SecretaryFullName": "Robert E. Rubin",
            "SecretaryNumber": "70th",
            "SecretaryTerm": "January 11, 1995 – July 2, 1999",
            "SecretaryBio": "70th United States Secretary of the Treasury In office January 11, 1995 – July 2, 1999",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Robert_Rubin",
            "Treasurer": "withrow",
            "TreasurerFullName": "Mary Ellen Withrow",
            "TreasurerNumber": "40th",
            "TreasurerTerm": "March 1, 1994 – January 20, 2001",
            "TreasurerBio": "40th Treasurer of the United States In office March 1, 1994 – January 20, 2001",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Mary_Ellen_Withrow"
        },
        {
            "Series": "1995",
            "Year": 1995,
            "Secretary": "rubin",
            "SecretaryFullName": "Robert E. Rubin",
            "SecretaryNumber": "70th",
            "SecretaryTerm": "January 11, 1995 – July 2, 1999",
            "SecretaryBio": "70th United States Secretary of the Treasury In office January 11, 1995 – July 2, 1999",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Robert_Rubin",
            "Treasurer": "withrow",
            "TreasurerFullName": "Mary Ellen Withrow",
            "TreasurerNumber": "40th",
            "TreasurerTerm": "March 1, 1994 – January 20, 2001",
            "TreasurerBio": "40th Treasurer of the United States In office March 1, 1994 – January 20, 2001",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Mary_Ellen_Withrow"
        },
        {
            "Series": "1993",
            "Year": 1993,
            "Secretary": "bentsen",
            "SecretaryFullName": "Lloyd Bentsen",
            "SecretaryNumber": "69th",
            "SecretaryTerm": "January 20, 1993 – December 22, 1994",
            "SecretaryBio": "69th United States Secretary of the Treasury In office January 20, 1993 – December 22, 1994",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Lloyd_Bentsen",
            "Treasurer": "withrow",
            "TreasurerFullName": "Mary Ellen Withrow",
            "TreasurerNumber": "40th",
            "TreasurerTerm": "March 1, 1994 – January 20, 2001",
            "TreasurerBio": "40th Treasurer of the United States In office March 1, 1994 – January 20, 2001",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Mary_Ellen_Withrow"
        },
        {
            "Series": "1990",
            "Year": 1990,
            "Secretary": "brady",
            "SecretaryFullName": "Nicholas F. Brady",
            "SecretaryNumber": "68th",
            "SecretaryTerm": "September 15, 1988 – January 17, 1993",
            "SecretaryBio": "68th United States Secretary of the Treasury; In office September 15, 1988 – January 17, 1993",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Nicholas_F._Brady",
            "Treasurer": "villalpando",
            "TreasurerFullName": "Catalina Vasquez Villalpando",
            "TreasurerNumber": "39th",
            "TreasurerTerm": "December 11, 1989 – January 20, 1993",
            "TreasurerBio": "39th Treasurer of the United States In office December 11, 1989 – January 20, 1993",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Catalina_Vasquez_Villalpando"
        },
        {
            "Series": "1988A",
            "Year": 1988,
            "Secretary": "brady",
            "SecretaryFullName": "Nicholas F. Brady",
            "SecretaryNumber": "68th",
            "SecretaryTerm": "September 15, 1988 – January 17, 1993",
            "SecretaryBio": "68th United States Secretary of the Treasury; In office September 15, 1988 – January 17, 1993",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Nicholas_F._Brady",
            "Treasurer": "villalpando",
            "TreasurerFullName": "Catalina Vasquez Villalpando",
            "TreasurerNumber": "39th",
            "TreasurerTerm": "December 11, 1989 – January 20, 1993",
            "TreasurerBio": "39th Treasurer of the United States In office December 11, 1989 – January 20, 1993",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Catalina_Vasquez_Villalpando"
        },
        {
            "Series": "1988",
            "Year": 1988,
            "Secretary": "brady",
            "SecretaryFullName": "Nicholas F. Brady",
            "SecretaryNumber": "68th",
            "SecretaryTerm": "September 15, 1988 – January 17, 1993",
            "SecretaryBio": "68th United States Secretary of the Treasury; In office September 15, 1988 – January 17, 1993",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Nicholas_F._Brady",
            "Treasurer": "ortega",
            "TreasurerFullName": "Katherine Davalos Ortega",
            "TreasurerNumber": "38th",
            "TreasurerTerm": "September 26, 1983 – July 1, 1989",
            "TreasurerBio": "38th Treasurer of the United States In office September 26, 1983 – July 1, 1989",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Katherine_D._Ortega"
        },
        {
            "Series": "1985",
            "Year": 1985,
            "Secretary": "baker",
            "SecretaryFullName": "James A. Baker III",
            "SecretaryNumber": "67th",
            "SecretaryTerm": "February 4, 1985 – August 17, 1988",
            "SecretaryBio": "67th United States Secretary of the Treasury In office February 4, 1985 – August 17, 1988",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/James_Baker",
            "Treasurer": "ortega",
            "TreasurerFullName": "Katherine Davalos Ortega",
            "TreasurerNumber": "38th",
            "TreasurerTerm": "September 26, 1983 – July 1, 1989",
            "TreasurerBio": "38th Treasurer of the United States In office September 26, 1983 – July 1, 1989",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Katherine_D._Ortega"
        },
        {
            "Series": "1981A",
            "Year": 1981,
            "Secretary": "regan",
            "SecretaryFullName": "Donald T. Regan",
            "SecretaryNumber": "66th",
            "SecretaryTerm": "January 22, 1981 – February 1, 1985",
            "SecretaryBio": "66th United States Secretary of the Treasury In office January 22, 1981 – February 1, 1985",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Donald_Regan",
            "Treasurer": "ortega",
            "TreasurerFullName": "Katherine Davalos Ortega",
            "TreasurerNumber": "38th",
            "TreasurerTerm": "September 26, 1983 – July 1, 1989",
            "TreasurerBio": "38th Treasurer of the United States In office September 26, 1983 – July 1, 1989",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Katherine_D._Ortega"
        },
        {
            "Series": "1981",
            "Year": 1981,
            "Secretary": "regan",
            "SecretaryFullName": "Donald T. Regan",
            "SecretaryNumber": "66th",
            "SecretaryTerm": "January 22, 1981 – February 1, 1985",
            "SecretaryBio": "66th United States Secretary of the Treasury In office January 22, 1981 – February 1, 1985",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Donald_Regan",
            "Treasurer": "buchanan",
            "TreasurerFullName": "Angela M Buchanan",
            "TreasurerNumber": "37th",
            "TreasurerTerm": "March 20, 1981 – July 5, 1983",
            "TreasurerBio": "37th Treasurer of the United States In office March 20, 1981 – July 5, 1983",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Bay_Buchanan"
        },
        {
            "Series": "1977A",
            "Year": 1977,
            "Secretary": "miller",
            "SecretaryFullName": "G. William Miller",
            "SecretaryNumber": "65th",
            "SecretaryTerm": "August 6, 1979 – January 20, 1981",
            "SecretaryBio": "65th United States Secretary of the Treasury In office August 6, 1979 – January 20, 1981",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/G._William_Miller",
            "Treasurer": "morton",
            "TreasurerFullName": "Azie Taylor Morton",
            "TreasurerNumber": "36th",
            "TreasurerTerm": "September 12, 1977 – January 20, 1981",
            "TreasurerBio": "36th Treasurer of the United States In office September 12, 1977 – January 20, 1981",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Azie_Taylor_Morton"
        },
        {
            "Series": "1977",
            "Year": 1977,
            "Secretary": "blumenthal",
            "SecretaryFullName": "W M Blumenthal",
            "SecretaryNumber": "64th",
            "SecretaryTerm": "January 23, 1977 – August 4, 1979",
            "SecretaryBio": "64th United States Secretary of the Treasury In office January 23, 1977 – August 4, 1979",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/W._Michael_Blumenthal",
            "Treasurer": "morton",
            "TreasurerFullName": "Azie Taylor Morton",
            "TreasurerNumber": "36th",
            "TreasurerTerm": "September 12, 1977 – January 20, 1981",
            "TreasurerBio": "36th Treasurer of the United States In office September 12, 1977 – January 20, 1981",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Azie_Taylor_Morton"
        },
        {
            "Series": "1974",
            "Year": 1974,
            "Secretary": "simon",
            "SecretaryFullName": "William E. Simon",
            "SecretaryNumber": "63rd",
            "SecretaryTerm": "May 9, 1974 – January 20, 1977",
            "SecretaryBio": "63rd United States Secretary of the Treasury In office May 9, 1974 – January 20, 1977",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/William_E._Simon",
            "Treasurer": "neff",
            "TreasurerFullName": "Francine I. Neff",
            "TreasurerNumber": "35th",
            "TreasurerTerm": "June 23, 1974 – January 20, 1977",
            "TreasurerBio": "35th Treasurer of the United States In office June 23, 1974 – January 20, 1977",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Francine_Irving_Neff"
        },
        {
            "Series": "1969D",
            "Year": 1969,
            "Secretary": "shultz",
            "SecretaryFullName": "George P. Shultz",
            "SecretaryNumber": "62nd",
            "SecretaryTerm": "June 12, 1972 – May 8, 1974",
            "SecretaryBio": "62nd United States Secretary of the Treasury In office June 12, 1972 – May 8, 1974",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/George_P._Shultz",
            "Treasurer": "banuelos",
            "TreasurerFullName": "Romana Acosta Bañuelos",
            "TreasurerNumber": "34th",
            "TreasurerTerm": "December 17, 1971 – February 14, 1974",
            "TreasurerBio": "34th Treasurer of the United States In office December 17, 1971 – February 14, 1974",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Romana_Acosta_Ba%C3%B1uelos"
        },
        {
            "Series": "1969C",
            "Year": 1969,
            "Secretary": "connally",
            "SecretaryFullName": "John B. Connally",
            "SecretaryNumber": "61st",
            "SecretaryTerm": "February 11, 1971 – June 12, 1972",
            "SecretaryBio": "61st United States Secretary of the Treasury In office February 11, 1971 – June 12, 1972",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_Connally",
            "Treasurer": "banuelos",
            "TreasurerFullName": "Romana Acosta Bañuelos",
            "TreasurerNumber": "34th",
            "TreasurerTerm": "December 17, 1971 – February 14, 1974",
            "TreasurerBio": "34th Treasurer of the United States In office December 17, 1971 – February 14, 1974",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Romana_Acosta_Ba%C3%B1uelos"
        },
        {
            "Series": "1969B",
            "Year": 1969,
            "Secretary": "connally",
            "SecretaryFullName": "John B. Connally",
            "SecretaryNumber": "61st",
            "SecretaryTerm": "February 11, 1971 – June 12, 1972",
            "SecretaryBio": "61st United States Secretary of the Treasury In office February 11, 1971 – June 12, 1972",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/John_Connally",
            "Treasurer": "kabis",
            "TreasurerFullName": "Dorothy Andrews Kabis",
            "TreasurerNumber": "33rd",
            "TreasurerTerm": "May 8, 1969 – July 3, 1971",
            "TreasurerBio": "33rd Treasurer of the United States In office May 8, 1969 – July 3, 1971",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Dorothy_Andrews_Elston_Kabis"
        },
        {
            "Series": "1969A",
            "Year": 1969,
            "Secretary": "kennedy",
            "SecretaryFullName": "David M Kennedy",
            "SecretaryNumber": "60th",
            "SecretaryTerm": "January 22, 1969 – February 11, 1971",
            "SecretaryBio": "60th United States Secretary of the Treasury In office January 22, 1969 – February 11, 1971",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/David_M._Kennedy",
            "Treasurer": "kabis",
            "TreasurerFullName": "Dorothy Andrews Kabis",
            "TreasurerNumber": "33rd",
            "TreasurerTerm": "May 8, 1969 – July 3, 1971",
            "TreasurerBio": "33rd Treasurer of the United States In office May 8, 1969 – July 3, 1971",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Dorothy_Andrews_Elston_Kabis"
        },
        {
            "Series": "1969",
            "Year": 1969,
            "Secretary": "kennedy",
            "SecretaryFullName": "David M Kennedy",
            "SecretaryNumber": "60th",
            "SecretaryTerm": "January 22, 1969 – February 11, 1971",
            "SecretaryBio": "60th United States Secretary of the Treasury In office January 22, 1969 – February 11, 1971",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/David_M._Kennedy",
            "Treasurer": "elston",
            "TreasurerFullName": "Dorothy Andrews Elston",
            "TreasurerNumber": "33rd",
            "TreasurerTerm": "May 8, 1969 – July 3, 1971",
            "TreasurerBio": "33rd Treasurer of the United States In office May 8, 1969 – July 3, 1971",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Dorothy_Andrews_Elston_Kabis"
        },
        {
            "Series": "1963B",
            "Year": 1963,
            "Secretary": "barr",
            "SecretaryFullName": "Joseph W. Barr",
            "SecretaryNumber": "59th",
            "SecretaryTerm": "December 21, 1968 – January 20, 1969",
            "SecretaryBio": "59th United States Secretary of the Treasury In office December 21, 1968 – January 20, 1969",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Joseph_W._Barr",
            "Treasurer": "granahan",
            "TreasurerFullName": "Kathryn O'Hay Granahan",
            "TreasurerNumber": "32nd",
            "TreasurerTerm": "January 9, 1963 – November 22, 1966",
            "TreasurerBio": "32nd Treasurer of the United States In office January 9, 1963 – November 22, 1966",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Kathryn_E._Granahan"
        },
        {
            "Series": "1963A",
            "Year": 1963,
            "Secretary": "fowler",
            "SecretaryFullName": "Henry H. Fowler",
            "SecretaryNumber": "58th",
            "SecretaryTerm": "April 1, 1965 – December 20, 1968",
            "SecretaryBio": "58th United States Secretary of the Treasury In office April 1, 1965 – December 20, 1968",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/Henry_H._Fowler",
            "Treasurer": "granahan",
            "TreasurerFullName": "Kathryn O'Hay Granahan",
            "TreasurerNumber": "32nd",
            "TreasurerTerm": "January 9, 1963 – November 22, 1966",
            "TreasurerBio": "32nd Treasurer of the United States In office January 9, 1963 – November 22, 1966",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Kathryn_E._Granahan"
        },
        {
            "Series": "1963",
            "Year": 1963,
            "Secretary": "dillon",
            "SecretaryFullName": "C. Douglas Dillon",
            "SecretaryNumber": "57th",
            "SecretaryTerm": "January 21, 1961 – April 1, 1965",
            "SecretaryBio": "57th United States Secretary of the Treasury In office January 21, 1961 – April 1, 1965",
            "SecretaryUrl": "https://en.wikipedia.org/wiki/C._Douglas_Dillon",
            "Treasurer": "granahan",
            "TreasurerFullName": "Kathryn O'Hay Granahan",
            "TreasurerNumber": "32nd",
            "TreasurerTerm": "January 9, 1963 – November 22, 1966",
            "TreasurerBio": "32nd Treasurer of the United States In office January 9, 1963 – November 22, 1966",
            "TreasurerUrl": "https://en.wikipedia.org/wiki/Kathryn_E._Granahan"
        }
    ];

    static bep: any = {
        "bepfw": {
            "Name": "Bureau of Engraving and Printing Western Currency facility",
            "Address1": "9000 Blue Mound Rd",
            "Address2": "",
            "City": "Fort Worth",
            "State": "TX",
            "Zip": "76131",
            "Lat": 32.900726,
            "Long": -97.348868
        },
        "bepw": {
            "Name": "Bureau of Engraving and Printing",
            "Address1": "301 14th St SW",
            "Address2": "",
            "City": "Washington",
            "State": "DC",
            "Zip": "20228",
            "Lat": 38.885743,
            "Long": -77.033709
        }
    };

    static districtList: District[] = [
        {
            name: 'Boston',
            letter: 'A',
            mainOfficeSvgId: 'd-boston',
            svgSectionId: 'boston',
            districtId: 1,
            color: '#75a560',
            dScale: 3.9,
            dx: -16000,
            dy: 200,
            numberOfBucks: 35000000,
            address: {
                city: 'Boston',
                state: 'MA',
                zip: '02210',
                address1: '600 Atlantic Avenue'
            },
            coverageArea: 'Covers the states of Maine, Massachusetts, New Hampshire, Rhode Island, and Vermont; and all but Fairfield County in Connecticut.',
            isCitySelected: false
        },
        {
            name: 'New York',
            letter: 'B',
            mainOfficeSvgId: 'd-new-york',
            svgSectionId: 'new-york',
            districtId: 2,
            color: '#75a560',
            dScale: 5,
            dx: -20000,
            dy: -1600,
            numberOfBucks: 555000000,
            address: {
                city: 'New York',
                state: 'NY',
                zip: '10045',
                address1: '33 Liberty Street'
            },
            coverageArea: 'Covers the state of New York; Fairfield County in Connecticut; and 12 counties in northern New Jersey, and serves the Commonwealth of Puerto Rico and the U.S. Virgin Islands.',
            isCitySelected: false
        },
        {
            name: 'philadelphia',
            letter: 'C',
            mainOfficeSvgId: 'd-philadelphia',
            svgSectionId: 'philadelphia',
            districtId: 3,
            color: '#75a560',
            dScale: 6.5,
            dx: -26000,
            dy: -5000,
            numberOfBucks: 55000000,
            address: {
                city: 'Philadelphia',
                state: 'PA',
                zip: '19106',
                address1: 'Ten Independence Mall'
            },
            coverageArea: 'Covers the state of Delaware; nine counties in southern New Jersey; and 48 counties in the eastern two-thirds of Pennsylvania.',
            isCitySelected: false
        },
        {
            name: 'cleveland',
            letter: 'D',
            mainOfficeSvgId: 'd-cleveland',
            svgSectionId: 'cleveland',
            districtId: 4,
            color: '#75a560',
            dScale: 4.5,
            dx: -15000,
            dy: -3800,
            numberOfBucks: 75000000,
            address: {
                city: 'Cleveland',
                state: 'OH',
                zip: '44114',
                address1: '1455 East Sixth Street'
            },
            coverageArea: 'Covers the state of Ohio; 56 counties in eastern Kentucky; 19 counties in western Pennsylvania; and 6 counties in northern West Virginia .',
            isCitySelected: false,
            branches: [
                {
                    name: 'Cincinnati',
                    svgId: 'd-cleveland-cincinnati',
                    parentDistrictId: 4,
                    address: {
                        address1: '150 East Fourth Street',
                        city: 'Cincinnati',
                        state: 'OH',
                        zip: '45202'
                    }
                },
                {
                    name: 'Pittsburgh',
                    svgId: 'd-cleveland-pittsburgh',
                    parentDistrictId: 4,
                    address: {
                        address1: '301 Grant Street',
                        address2: 'One Oxford Centre Suite 3000',
                        city: 'Pittsburgh',
                        state: 'PA',
                        zip: '15219'
                    }
                }
            ]
        },
        {
            name: 'richmond',
            letter: 'E',
            mainOfficeSvgId: 'd-richmond',
            svgSectionId: 'richmond',
            districtId: 5,
            color: '#75a560',
            dScale: 3.25,
            dx: -11500,
            dy: -3400,
            numberOfBucks: 75000000,
            address: {
                address1: '701 East Byrd Street',
                city: 'Richmond',
                state: 'VA',
                zip: '23219'
            },
            coverageArea: 'Covers the states of Maryland, Virginia, North Carolina, and South Carolina; 49 counties constituting most of West Virginia; and the District of Columbia.',
            isCitySelected: false,
            branches: [
                {
                    name: 'baltimore',
                    svgId: 'd-richmond-baltimore',
                    parentDistrictId: 5,
                    address: {
                        address1: '502 South Sharp Street',
                        city: 'Baltimore',
                        state: 'MD',
                        zip: '21201'
                    }
                },
                {
                    name: 'charlotte',
                    svgId: 'd-richmond-charlotte',
                    parentDistrictId: 5,
                    address: {
                        address1: '530 Trade Street',
                        city: 'Charlotte',
                        state: 'NC',
                        zip: '28202'
                    }
                }
            ]
        },
        {
            name: 'atlanta',
            letter: 'F',
            mainOfficeSvgId: 'd-atlanta',
            svgSectionId: 'atlanta',
            districtId: 6,
            color: '#75a560',
            dScale: 2.45,
            dx: -6300,
            dy: -3700,
            numberOfBucks: 350000000,
            address: {
                address1: '1000 Peachtree Street NE',
                city: 'Atlanta',
                state: 'GA',
                zip: '30309'
            },
            coverageArea: 'Covers the states of Alabama, Florida, and Georgia; 74 counties in the eastern two-thirds of Tennessee; 38 parishes of southern Louisiana; and 43 counties of southern Mississippi.',
            isCitySelected: false,
            branches: [
                {
                    name: 'birmingham',
                    svgId: 'd-atlanta-birmingham',
                    parentDistrictId: 6,
                    address: {
                        city: 'Birmingham',
                        state: 'AL',
                        zip: '35242',
                        address1: '524 Liberty Parkway',
                        address2: ''
                    }
                },
                {
                    name: 'jacksonville',
                    svgId: 'd-atlanta-jacksonville',
                    parentDistrictId: 6,
                    address: {
                        city: 'Jacksonville',
                        state: 'FL',
                        zip: '32204',
                        address1: '800 Water Street',
                        address2: ''
                    }
                },
                {
                    name: 'miami',
                    svgId: 'd-atlanta-miami',
                    parentDistrictId: 6,
                    address: {
                        city: 'Miami',
                        state: 'FL',
                        zip: '33178',
                        address1: '9100 Northwest 36th Street',
                        address2: ''
                    }
                },
                {
                    name: 'nashville',
                    svgId: 'd-atlanta-nashville',
                    parentDistrictId: 6,
                    address: {
                        city: 'Nashville',
                        state: 'TN',
                        zip: '37203',
                        address1: '301 Rosa L. Parks Blvd.',
                        address2: ''
                    }
                },
                {
                    name: 'new orleans',
                    svgId: 'd-atlanta-new-orleans',
                    parentDistrictId: 6,
                    address: {
                        city: 'New Orleans',
                        state: 'LA',
                        zip: '70130',
                        address1: '525 St. Charles Avenue',
                        address2: ''
                    }
                }
            ]
        },
        {
            name: 'chicago',
            letter: 'G',
            mainOfficeSvgId: 'd-chicago',
            svgSectionId: 'chicago',
            districtId: 7,
            color: '#75a560',
            dScale: 3.6,
            dx: -9000,
            dy: -1600,
            numberOfBucks: 75000000,
            address: {
                city: 'Chicago',
                state: 'IL',
                zip: '60604',
                address1: '230 South LaSalle Street',
                address2: ''
            },
            coverageArea: 'Covers the state of Iowa; 68 counties of northern Indiana; 50 counties of northern Illinois; 68 counties of southern Michigan; and 46 counties of southern Wisconsin.',
            isCitySelected: false,
            branches: [
                {
                    name: 'detroit',
                    svgId: 'd-chicago-detroit',
                    parentDistrictId: 7,
                    address: {
                        city: 'Detroit',
                        state: 'MI',
                        zip: '48207',
                        address1: '1600 East Warren Avenue',
                        address2: ''
                    }
                }
            ]
        },
        {
            name: 'st louis',
            letter: 'H',
            mainOfficeSvgId: 'd-st-louis',
            svgSectionId: 'st-louis',
            districtId: 8,
            color: '#75a560',
            dScale: 3.5,
            dx: -8500,
            dy: -4200,
            numberOfBucks: 75000000,
            address: {
                city: 'St. Louis',
                state: 'MO',
                zip: '63102',
                address1: 'One Federal Reserve Bank Plaza',
                address2: 'Broadway and Locust Streets'
            },
            coverageArea: 'Covers the state of Arkansas; 44 counties in southern Illinois; 24 counties in southern Indiana; 64 counties in western Kentucky; 39 counties in northern Mississippi; 71 counties in central and eastern Missouri; the city of St. Louis; and 21 counties in western Tennessee.',
            isCitySelected: false,
            branches: [
                {
                    name: 'little rock',
                    svgId: 'd-st-louis-little-rock',
                    parentDistrictId: 8,
                    address: {
                        city: 'Little Rock',
                        state: 'AR',
                        zip: '72203',
                        address1: 'Stephens Building, 111 Center Street',
                        address2: 'Suite 1000'
                    }
                },
                {
                    name: 'louisville',
                    svgId: 'd-st-louis-louisville',
                    parentDistrictId: 8,
                    address: {
                        city: 'Louisville',
                        state: 'KY',
                        zip: '40202',
                        address1: '101 South Fifth Street',
                        address2: 'Suite 1920'
                    }
                },
                {
                    name: 'memphis',
                    svgId: 'd-st-louis-memphis',
                    parentDistrictId: 8,
                    address: {
                        city: 'Memphis',
                        state: 'TN',
                        zip: '38103',
                        address1: '200 North Main Street',
                        address2: ''
                    }
                }
            ]
        },
        {
            name: 'minneapolis',
            letter: 'I',
            mainOfficeSvgId: 'd-minneapolis',
            svgSectionId: 'minneapolis',
            districtId: 9,
            color: '#75a560',
            dScale: 1.8,
            dx: -1600,
            dy: 700,
            numberOfBucks: 5000000,
            address: {
                city: 'Minneapolis',
                state: 'MN',
                zip: '55401',
                address1: '90 Hennepin Avenue',
                address2: ''
            },
            coverageArea: 'Covers the states of Minnesota, Montana, North Dakota, and South Dakota; the Upper Peninsula of Michigan; and 26 counties in northern Wisconsin.',
            isCitySelected: false,
            branches: [
                {
                    name: 'helena',
                    svgId: 'd-minneapolis-helena',
                    parentDistrictId: 9,
                    address: {
                        city: 'Helena',
                        state: 'MT',
                        zip: '59601',
                        address1: '100 Neill Avenue',
                        address2: ''
                    }
                }
            ]
        },
        {
            name: 'kansas city',
            letter: 'J',
            mainOfficeSvgId: 'd-kansas-city',
            svgSectionId: 'kansas-city',
            districtId: 10,
            color: '#75a560',
            dScale: 2.2,
            dx: -1900,
            dy: -1200,
            numberOfBucks: 100000000,
            address: {
                city: 'Kansas City',
                state: 'MO',
                zip: '64198',
                address1: '1 Memorial Drive',
                address2: ''
            },
            coverageArea: 'Covers the states of Colorado, Kansas, Nebraska, Oklahoma, and Wyoming; 43 counties in western Missouri; and 14 counties in northern New Mexico.',
            isCitySelected: false,
            branches: [
                {
                    name: 'denver',
                    svgId: 'd-kansas-city-denver',
                    parentDistrictId: 10,
                    address: {
                        city: 'Denver',
                        state: 'CO',
                        zip: '80202',
                        address1: '1020 16th Street',
                        address2: ''
                    }
                },
                {
                    name: 'oklahoma city',
                    svgId: 'd-kansas-city-oklahoma-city',
                    parentDistrictId: 10,
                    address: {
                        city: 'Oklahoma City',
                        state: 'OK',
                        zip: '73102',
                        address1: '211 N. Robinson',
                        address2: 'Two Leadership Square, Suite 300'
                    }
                },
                {
                    name: 'omaha',
                    svgId: 'd-kansas-city-omaha',
                    parentDistrictId: 10,
                    address: {
                        city: 'Omaha',
                        state: 'NE',
                        zip: '68102',
                        address1: '2201 Farnam Street',
                        address2: ''
                    }
                }
            ]
        },
        {
            name: 'dallas',
            letter: 'K',
            mainOfficeSvgId: 'd-dallas',
            svgSectionId: 'dallas',
            districtId: 11,
            color: '#75a560',
            dScale: 2.5,
            dx: -3000,
            dy: -4200,
            numberOfBucks: 150000000,
            address: {
                address1: '2200 North Pearl Street',
                city: 'Dallas',
                state: 'TX',
                zip: '75201'
            },
            coverageArea: 'Covers the state of Texas; 26 parishes in northern Louisiana; and 18 counties in southern New Mexico.',
            isCitySelected: false,
            branches: [
                {
                    name: 'el paso',
                    svgId: 'd-dallas-el-paso',
                    parentDistrictId: 11,
                    address: {
                        city: 'El Paso',
                        state: 'TX',
                        zip: '79901',
                        address1: '301 East Main Street',
                        address2: ''
                    }
                },
                {
                    name: 'houston',
                    svgId: 'd-dallas-houston',
                    parentDistrictId: 11,
                    address: {
                        city: 'Houston',
                        state: 'TX',
                        zip: '77019',
                        address1: '1801 Allen Parkway',
                        address2: ''
                    }
                },
                {
                    name: 'san antonio',
                    svgId: 'd-dallas-san-antonio',
                    parentDistrictId: 11,
                    address: {
                        city: 'San Antonio',
                        state: 'TX',
                        zip: '78204',
                        address1: '402 Dwyer Ave',
                        address2: ''
                    }
                }
            ]
        },
        {
            name: 'san francisco',
            letter: 'L',
            mainOfficeSvgId: 'd-san-francisco',
            svgSectionId: 'san-francisco',
            districtId: 12,
            color: '#75a560',
            dScale: 1,
            dx: 200,
            dy: 0,
            numberOfBucks: 650000000,
            address: {
                city: 'San Francisco',
                state: 'CA',
                zip: '94105',
                address1: '101 Market Street',
                address2: ''
            },
            coverageArea: 'Covers the states of Alaska, Arizona, California, Hawaii, Idaho, Nevada, Oregon, Utah, and Washington, and serves American Samoa, Guam, and the Commonwealth of the Northern Mariana Islands.',
            isCitySelected: false,
            branches: [
                {
                    name: 'los angeles',
                    svgId: 'd-san-francisco-los-angeles',
                    parentDistrictId: 12,
                    address: {
                        city: 'Los Angeles',
                        state: 'CA',
                        zip: '90015',
                        address1: '950 South Grand Avenue',
                        address2: ''
                    }
                },
                {
                    name: 'portland',
                    svgId: 'd-san-francisco-portland',
                    parentDistrictId: 12,
                    address: {
                        city: 'Portland',
                        state: 'OR',
                        zip: '97201',
                        address1: '1500 SW First Avenue',
                        address2: ''
                    }
                },
                {
                    name: 'salt lake city',
                    svgId: 'd-san-francisco-salt-lake-city',
                    parentDistrictId: 12,
                    address: {
                        city: 'Salt Lake City',
                        state: 'UT',
                        zip: '84111',
                        address1: '120 South State Street',
                        address2: ''
                    }
                },
                {
                    name: 'seattle',
                    svgId: 'd-san-francisco-seattle',
                    parentDistrictId: 12,
                    address: {
                        city: 'Renton',
                        state: 'WA',
                        zip: '98057',
                        address1: '2700 Naches Avenue SW',
                        address2: ''
                    }
                }
            ]
        }
    ];
}
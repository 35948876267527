import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TraceContainerComponent } from './trace-container/trace-container.component';
import { TraceHomeComponent } from './trace-home/trace-home.component';
import { ValidSerialRouteGuard } from './valid-serial-route-guard';



const routes: Routes = [
    {
        path: 'trace',
        component: TraceHomeComponent
    },
    {
        path: 'trace/:sn',
        component: TraceContainerComponent,
        canActivate: [ValidSerialRouteGuard]
    },
    {
        path: 'trace/:sn/fed-map',
        component: TraceContainerComponent,
        canActivate: [ValidSerialRouteGuard]
    },
    {
        path: 'trace/:sn/buck-info',
        component: TraceContainerComponent,
        canActivate: [ValidSerialRouteGuard]
    },
    {
        path: 'invalidserial',
        component: TraceHomeComponent
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class TraceRoutingModule { }

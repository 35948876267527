import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { TraceDetailComponent } from "./trace-detail/trace-detail.component";
import { TraceHomeComponent } from "./trace-home/trace-home.component";
import { TraceRoutingModule } from "./trace-routing.module";
import { FedMapOldComponent } from './fed-map-old/fed-map.component';
import { FedMapComponent } from "./fed-map/fed-map.component";
import { BuckInfoComponent } from './buck-info/buck-info.component';
import { StoreModule } from "@ngrx/store";
import { traceReducer } from "./state/trace.reducer";
import { TraceContainerComponent } from './trace-container/trace-container.component';
import { EffectsModule } from "@ngrx/effects";
import { TraceEffects } from "./state/trace.effects";
import { TraceService } from "./services/trace.service";
import { HttpClientModule } from "@angular/common/http";
//6LeTU-8cAAAAAKg0elfx53exRdgyMX7eZX-mdhXD
@NgModule({
    declarations: [
        TraceHomeComponent,
        TraceDetailComponent,
        FedMapOldComponent,
        FedMapComponent,
        BuckInfoComponent,
        TraceContainerComponent
    ],
    exports: [],
    imports: [
        SharedModule,
        TraceRoutingModule,
        HttpClientModule,
        StoreModule.forFeature('trace', traceReducer),
        EffectsModule.forFeature([TraceEffects])
    ],
    providers: [TraceService]
})
export class TraceModule { }


export class BuckElementLocation {
    type: BuckElementType;
    x: number;
    y: number;
    scale: number;
    id: string;
    zoomX: number;
    zoomY: number;
    zoomScale: number;
}

export enum BuckElementType {
    'SEAL',
    'SERIAL_TOP_RIGHT',
    'SERIAL_BOTTOM_LEFT',
    'FW',
    'FED_NUMBER_TOP_LEFT',
    'FED_NUMBER_TOP_RIGHT',
    'FED_NUMBER_BOTTOM_LEFT',
    'FED_NUMBER_BOTTOM_RIGHT',
    'TREASURER',
    'SECRETARY',
    'TREASURER_LABEL',
    'SECRETARY_LABEL',
    'SERIES',
    'PLATE',
    'GRID'
}

export class Buck {
    constructor(
        public SN: string,
        public SD?: string,
        public FRD?: number,
        public DN?: number,
        public FRL?: string,
        public PX?: string,
        public SX?: string,
        public FW?: boolean,
        public HIST?: Array<Trace>,
        public SRS?: string,
        public BEP?: number,
        public CDT?: Date,
        public UDT?: Date,
        public MAT?: MatchedPatterns,
        public DBMAT?: boolean,
        public B64_CAP?: string,
        public DSRSCT?: number,
        public DSTCITY?: string,
        public CA?: boolean,
        public Errors?: Array<string>
    ) { }
}
export class Trace {
    constructor(
        public TraceId?: string,
        public Lat?: number,
        public Long?: number,
        public UserId?: number,
        public City?: string,
        public State?: string,
        public Zip?: string,
        public TraceDate?: Date,
        public FullAddress?: string,
        public Address1?: string,
        public Address2?: string,
        public Name?: string,
        public FromAPI?: boolean,
        public Photo?: s3_file_and_thumb,
    ) { }
}
export class MatchedPatterns {
    public IsAllPairs2: boolean = false;
    public IsAllPairs4: boolean = false;
    public IsRepeatingPairs2: boolean = false;
    public IsRepeatingPairs4: boolean = false;
    public IsConsectutiveAscending: boolean = false;
    public IsConsectutiveDescending: boolean = false;
    public IsConsectutivePairAscending: boolean = false;
    public IsConsectutivePairDescending: boolean = false;
    public IsUniqueDigits: boolean = false;
    public IsOneDigit: boolean = false;
    public IsTwoDigits: boolean = false;
    public IsSixOrMoreSameDigit: boolean = false;
    public IsPalindrome: boolean = false;
    public IsDate: boolean = false;
}
export class s3_file_and_thumb {
    constructor(
        public file_id?: string,
        public url?: string,
        public location?: string,
        public width?: number,
        public height?: number,
        public e_tag?: string,
        public bucket_name?: string,
        public key?: string,
        public expiration?: Date,
        public content_length?: number,
        public original_file_name?: string,
        public base64?: string,

        public thumb_e_tag?: string,
        public thumb_key?: string,
        public thumb_url?: string,
        public thumb_location?: string,
        public thumb_width?: number,
        public thumb_height?: number,
        public thumb_content_length?: number,
        public thumb_expiration?: Date,
        public thumb_base64?: string
    ) { }
}




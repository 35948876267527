import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fed-map-old',
  templateUrl: './fed-map.component.html',
  styleUrls: ['./fed-map.component.scss']
})
export class FedMapOldComponent implements OnInit {
  svg: any = null;
  districtList: District[] = [
    {
      name: 'Boston',
      svgId: 'd-boston',
      svgSectionId: 'boston',
      districtId: 1,
      color: '#B2D2A4',
      dScale: 3.0,
      dx: -45,
      dy: 38
    },
    {
      name: 'New York',
      svgId: 'd-new-york',
      svgSectionId: 'new-york',
      districtId: 2,
      color: '#B2D2A4',
      dScale: 3.7,
      dx: -38,
      dy: 31
    },
    {
      name: 'philadelphia',
      svgId: 'd-philadelphia',
      svgSectionId: 'philadelphia',
      districtId: 3,
      color: '#B2D2A4',
      dScale: 6.5,
      dx: -36,
      dy: 22
    },
    {
      name: 'cleveland',
      svgId: 'd-cleveland',
      svgSectionId: 'cleveland',
      districtId: 4,
      color: '#B2D2A4',
      dScale: 3.2,
      dx: -27,
      dy: 15,
      branches: [
        {
          name: 'Cincinnati',
          svgId: 'd-cleveland-cincinnati',
          parentDistrictId: 4
        },
        {
          name: 'Pittsburgh',
          svgId: 'd-cleveland-pittsburgh',
          parentDistrictId: 4
        }
      ]
    },
    {
      name: 'richmond',
      svgId: 'd-richmond',
      svgSectionId: 'richmond',
      districtId: 5,
      color: '#B2D2A4',
      dScale: 2.1,
      dx: -33,
      dy: 5,
      branches: [
        {
          name: 'baltimore',
          svgId: 'd-richmond-baltimore',
          parentDistrictId: 5
        },
        {
          name: 'charlotte',
          svgId: 'd-richmond-charlotte',
          parentDistrictId: 5
        }
      ]
    },
    {
      name: 'atlanta',
      svgId: 'd-atlanta',
      svgSectionId: 'atlanta',
      districtId: 6,
      color: '#B2D2A4',
      dScale: 1.5,
      dx: -22,
      dy: -13,
      branches: [
        {
          name: 'birmingham',
          svgId: 'd-atlanta-birmingham',
          parentDistrictId: 6
        },
        {
          name: 'jacksonville',
          svgId: 'd-atlanta-jacksonville',
          parentDistrictId: 6
        },
        {
          name: 'miami',
          svgId: 'd-atlanta-miami',
          parentDistrictId: 6
        },
        {
          name: 'nashville',
          svgId: 'd-atlanta-nashville',
          parentDistrictId: 6
        },
        {
          name: 'new orleans',
          svgId: 'd-atlanta-new-orleans',
          parentDistrictId: 6
        }
      ]
    },
    {
      name: 'chicago',
      svgId: 'd-chicago',
      svgSectionId: 'chicago',
      districtId: 7,
      color: '#B2D2A4',
      dScale: 2.7,
      dx: -13,
      dy: 23,
      branches: [
        {
          name: 'detroit',
          svgId: 'd-chicago-detroit',
          parentDistrictId: 7
        }
      ]
    },
    {
      name: 'st louis',
      svgId: 'd-st-louis',
      svgSectionId: 'st-louis',
      districtId: 8,
      color: '#B2D2A4',
      dScale: 2.3,
      dx: -13,
      dy: 3,
      branches: [
        {
          name: 'little rock',
          svgId: 'd-st-louis-little-rock',
          parentDistrictId: 8
        },
        {
          name: 'louisville',
          svgId: 'd-st-louis-louisville',
          parentDistrictId: 8
        },
        {
          name: 'memphis',
          svgId: 'd-st-louis-memphis',
          parentDistrictId: 8
        }
      ]
    },
    {
      name: 'minneapolis',
      svgId: 'd-minneapolis',
      svgSectionId: 'minneapolis',
      districtId: 9,
      color: '#B2D2A4',
      dScale: 0.7,
      dx: 5,
      dy: 40,
      branches: [
        {
          name: 'helena',
          svgId: 'd-minneapolis-helena',
          parentDistrictId: 9
        }
      ]
    },
    {
      name: 'kansas city',
      svgId: 'd-kansas-city',
      svgSectionId: 'kansas-city',
      districtId: 10,
      color: '#B2D2A4',
      dScale: 1.3,
      dx: 9,
      dy: 11,
      branches: [
        {
          name: 'denver',
          svgId: 'd-kansas-city-denver',
          parentDistrictId: 10
        },
        {
          name: 'oklahoma city',
          svgId: 'd-kansas-city-oklahoma-city',
          parentDistrictId: 10
        },
        {
          name: 'omaha',
          svgId: 'd-kansas-city-omaha',
          parentDistrictId: 10
        }
      ]
    },
    {
      name: 'dallas',
      svgId: 'd-dallas',
      svgSectionId: 'dallas',
      districtId: 11,
      color: '#B2D2A4',
      dScale: 1.5,
      dx: 6,
      dy: -17,
      branches: [
        {
          name: 'el paso',
          svgId: 'd-dallas-el-paso',
          parentDistrictId: 11
        },
        {
          name: 'houston',
          svgId: 'd-dallas-houston',
          parentDistrictId: 11
        },
        {
          name: 'san antonio',
          svgId: 'd-dallas-san-antonio',
          parentDistrictId: 11
        }
      ]
    },
    {
      name: 'san francisco',
      svgId: 'd-san-francisco',
      svgSectionId: 'san-francisco',
      districtId: 12,
      color: '#B2D2A4',
      dScale: -.04,
      dx: 0,
      dy: -1,
      branches: [
        {
          name: 'los angeles',
          svgId: 'd-san-francisco-los-angeles',
          parentDistrictId: 12
        },
        {
          name: 'portland',
          svgId: 'd-san-francisco-portland',
          parentDistrictId: 12
        },
        {
          name: 'salt lake city',
          svgId: 'd-san-francisco-salt-lake-city',
          parentDistrictId: 12
        },
        {
          name: 'seattle',
          svgId: 'd-san-francisco-seattle',
          parentDistrictId: 12
        }
      ]
    }
  ];

  NEUTRAL_COLOR: string = '#9d9d9d';

  constructor() { }

  ngOnInit(): void {
    this.clearMap();
    document.getElementById("left-button").onclick = () => this.pan("left");
    document.getElementById("right-button").onclick = () => this.pan("right");
    document.getElementById("up-button").onclick = () => this.pan("up");
    document.getElementById("down-button").onclick = () => this.pan("down");

    document.getElementById("zoom-in-button").onclick = () => this.zoom("in");
    document.getElementById("zoom-out-button").onclick = () => this.zoom("out");
    this.changeDistrict(this.districtList[11]);
  }

  changeDistrict = (district: District) => {
    this.svg = document.getElementById("svg");
    this.clearMap();
    this.removePulse();
    this.svg.getElementById(district.svgSectionId).setAttribute('style', `fill: ${district.color}`);
    this.svg.getElementById(district.svgId).setAttribute('style', `animation: pulse 1.5s ease-in-out infinite`);
    this.svg.getElementById(district.svgId).setAttribute('class', `pulse`);


    this.svg = document.getElementById("svg");
    const { scale, x, y } = this.getTransformParameters(this.svg);
    this.svg.style.transform = this.getTransformString(scale + district.dScale, x + district.dx, y + district.dy);
  }

  pulseBranch(district: District, branch: Branch) {
    this.changeDistrict(district);
    this.removePulse();
    this.svg.getElementById(branch.svgId).setAttribute('style', `animation: pulse1 1.5s ease-in-out infinite`);
    this.svg.getElementById(branch.svgId).setAttribute('class', `pulse1`);
  }

  removePulse() {
    this.districtList.forEach(district => {
      this.svg.getElementById(district.svgId).setAttribute('style', ``);
      this.svg.getElementById(district.svgId).setAttribute('style', `fill: #2C5E1A`);
      this.svg.getElementById(district.svgId).setAttribute('class', ``);
      if (district.branches) {
        district.branches.forEach(branch => {
          this.svg.getElementById(branch.svgId).setAttribute('class', ``);
          this.svg.getElementById(branch.svgId).setAttribute('style', ``);
        })
      }
    });
  }

  // removeDistrictPulse() {
  //   this.districtList.forEach(district => {
  //     this.svg.getElementById(district.svgId).setAttribute('style', ``);
  //     this.svg.getElementById(district.svgId).setAttribute('style', `fill: #2C5E1A`);
  //     this.svg.getElementById(district.svgId).setAttribute('class', ``);
  //   });
  // }


  clearMap() {
    this.svg = document.getElementById("svg");
    this.svg.setAttribute('style', `fill: ${this.NEUTRAL_COLOR}`);
    this.districtList.forEach(district => {
      this.svg.getElementById(district.svgSectionId).setAttribute('style', `fill: ${this.NEUTRAL_COLOR}`);
    });
  }

  removePanZoom() {
    this.svg = document.getElementById("svg");
    const { scale, x, y } = this.getTransformParameters(this.svg);
    this.svg.style.transform = this.getTransformString(scale, x, y);
  }

  resetMap() {
    this.clearMap();
    this.removePulse();
    this.removePanZoom();
  }

  getTransformParameters = (element) => {
    const transform = element.style.transform;
    let scale = 1,
      x = 0,
      y = 0;

    if (transform.includes("scale"))
      scale = parseFloat(transform.slice(transform.indexOf("scale") + 6));
    if (transform.includes("translateX"))
      x = parseInt(transform.slice(transform.indexOf("translateX") + 11));
    if (transform.includes("translateY"))
      y = parseInt(transform.slice(transform.indexOf("translateY") + 11));

    return { scale, x, y };
  };

  getTransformString = (scale, x, y) => "scale(" + scale + ") " + "translateX(" + x + "%) translateY(" + y + "%)";



  pan = (direction) => {
    this.svg = document.getElementById("svg");
    const { scale, x, y } = this.getTransformParameters(this.svg);
    let dx = 0,
      dy = 0;
    switch (direction) {
      case "left":
        dx = -3;
        break;
      case "right":
        dx = 3;
        break;
      case "up":
        dy = -3;
        break;
      case "down":
        dy = 3;
        break;
    }
    this.svg.style.transform = this.getTransformString(scale, x + dx, y + dy);
  };

  zoom = (direction) => {
    this.svg = document.getElementById("svg");
    const { scale, x, y } = this.getTransformParameters(this.svg);
    let dScale = 0.1;
    if (direction == "out") dScale *= -1;
    if (scale == 0.1 && direction == "out") dScale = 0;
    this.svg.style.transform = this.getTransformString(scale + dScale, x, y);
  };

}

export class District {
  name: string;
  svgSectionId: string;
  svgId: string;
  districtId: number;
  color: string;
  dScale: number;
  dx: number;
  dy: number;
  branches?: Branch[];
}
export class Branch {
  name: string;
  svgId: string;
  parentDistrictId: number;
}


<form [formGroup]="buckForm" class="h-100">
    <div class="display-flex flex-direction-column  justify-content-c h-100">
        <div class="display-flex flex-direction-row justify-content-c">
            <div class="txt-align-c">
                <div [hidden]="isSuccess">
                    <div class="txt-sm-r m-b-lg" [hidden]="isInvalid">
                        Get started by entering the serial number
                    </div>
                    <div class="txt-sm-r m-b-lg" [hidden]="!isInvalid">
                        Invalid Serial. Please re-enter
                    </div>
                </div>
                <div [hidden]="!isSuccess">
                    <div class="txt-sm-r txt-align-c m-b-lg">
                        CONGRATULATIONS
                        <!-- <br><div style="font-size: 27px;">We officially launch <br>
                            12/02/2021 (Palindrome Day)</div> -->
                    </div>
                </div>
                <div>
                    <input type="text" class="initial-serial" placeholder="enter a buck" formControlName="serialNumber" maxlength="10" (keydown)="makeValid()" (keydown.enter)="submitSerial();" pattern="^[a-lA-L]{1}\d{8}[a-np-yA-NP-Y*]{1}$" [ngClass]="{'invalid':isInvalid}" />
                </div>
                <div class="txt-xsm-r" style="color:peru;">
                    Use (*) for star
                </div>
                <div>
                    <button class="buck-button" (click)="submitSerial()">Trace</button>
                </div>
                <div>
                    <!-- <recaptcha [size]="'invisible'" [hide]="false" (captchaResponse)="resolved($event)">
                    </recaptcha> -->
                    <!-- <re-captcha formControlName="recaptchaReactive" data-size="compact" (resolved)="resolved($event)" #captchaRef="reCaptcha" siteKey="{{siteKey}}"></re-captcha> -->
                </div>
            </div>
        </div>
    </div>
</form>
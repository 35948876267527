import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { TraceService } from '../services/trace.service';
import { of } from 'rxjs';
import * as TraceActions from './trace.actions'

@Injectable()
export class TraceEffects {
    constructor(private actions$: Actions, private traceService: TraceService) { }

    loadBuck$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TraceActions.loadBuck),
            mergeMap((action) => this.traceService.getBuck(action.serialNumber).pipe(
                map(buck => TraceActions.loadBuckSuccess({ currentBuck: buck })),
                catchError(error => of(TraceActions.loadBuckFail({ error })))
            ))
        )
    })
}